export interface ISesionUsuarios
{
    SesionUsuarioId:number,
    EmpresaId:number,
    UsuarioId:number,
    AppUsuarioId:number,
    TokenSession:string
    TokenHub:string
    Ip:string        
    Fecha:any,
    FechaVigencia:any
    Activo:boolean        
    Explorador:string        
    IsApp:string
    Error:string
    IsActive:string
    SessionTime:number
}
export class SesionUsuarios
{
    constructor(data?: any)
    {
        Object.assign(this, data);        
    }
    public SesionUsuarioId = 0;
    public EmpresaId = 0;
    public UsuarioId  = 0;
    public AppUsuarioId = 0;
    public TokenSession  = '';
    public TokenHub = '';
    public Ip = '' ;
    public Fecha = null;
    public FechaVigencia = null;
    public Activo = false;
    public Explorador = '';
    public IsApp = '';
    public Error = '';
    public IsActive = '';
    public SessionTime = 0;
}

import { eTipoFile } from '@/model/GloblasEnum';
import { IFileData } from '@/model/IFileData';
import { IDocumentoEvidencia } from '@/model/tickets/ITicket';
import { saveAs } from 'file-saver';
import { ArrayBufferaToBase64, Base64ToArrayBuffer,Base64ToArrayBuffer2 } from './ui/GlobalFunctions';
import { UserPhoto } from './ui/CameraServiceUI';
export class StorageService
{
  public async SaveFile(fileName: string, content: any,type:string)
  {
    if(content)
    {    
      const blob = new Blob([content] , { type: type});
      saveAs(blob,  fileName? fileName:'file-document' );  
    }      
  }
  protected base64ToArrayBuffer(base64: string):ArrayBufferLike
  {
    return Base64ToArrayBuffer(base64);
  }
  public async SaveBase64String(fileName: string, content: string)
  {
    if(content)
    {    
      const blob = new Blob([Base64ToArrayBuffer(content)]);
      saveAs(blob,  fileName? fileName:'file-document');    
    }      
  }
  public PhotoToDocumentoEvidencia(photo: UserPhoto):IDocumentoEvidencia|null
  {
    let b64 = '';
    if(!photo) return null;
    if(photo.tipo === 2)
    {
      const sResult = photo.content64.split(',');
      if(!sResult|| sResult.length < 2) return null;
      b64 = photo.content64.split(',')[1];
    }
    else
    {
      b64 = photo.content64;
    }
    
    const evidencia:IDocumentoEvidencia = {        
      DocumentoEvidenciaId :0,
      DocumentoId:0,
      Tabla :'',
      Documento :photo.name,
      Miniatura :0,
      Activo :true,
      Content:Base64ToArrayBuffer2(b64),
      Content64:b64,
      Tipo : 'img',
      Borrar:false
    };    
    return evidencia; 
  }
  public ToDocumentoEvidencia(files: IFileData[]):IDocumentoEvidencia
  {
    const evidencia:IDocumentoEvidencia = {        
      DocumentoEvidenciaId :0,
      DocumentoId:0,
      Tabla :'',
      Documento :'',
      Miniatura :0,
      Activo :false,
      Content:'',
      Content64:'',
      Tipo : '',
      Borrar:false
    };
    if(files && files.length > 0)
    {
      const file = files[0];
      const tipo = this.getTypeDoc(file.type);        
      evidencia.Documento = file.fileName;               
      evidencia.Content = file.fileContent;
      evidencia.Content64 = ArrayBufferaToBase64(file.fileContent);
      evidencia.Tipo = tipo === eTipoFile.Image? 'img':'doc';
      evidencia.Activo = true;                            
    }
    return evidencia; 
  }
  protected getTypeDoc(type:any):eTipoFile
  {       
    let tipo: any = eTipoFile.Other;      
    switch(type)
    {
      case 'image/apng': //Animated Portable Network Graphics (APNG)
      case 'image/avif' : //AV1 Image File Format (AVIF)
      case 'image/gif': //Graphics Interchange Format (GIF)
      case 'image/jpeg': //Joint Photographic Expert Group image (JPEG)
      case 'image/png': //Portable Network Graphics (PNG)
      case 'image/svg+xml': //Scalable Vector Graphics (SVG)
      case 'image/webp': //Web Picture format (WEBP)          
        tipo = eTipoFile.Image;
        
        break;
      case 'application/pdf':
        tipo = eTipoFile.Pdf;
        break;
    }
    return tipo;

  }
}
export enum eTipoNotificacion
{
  Normal = 0,
  Warning = 10,
  Error = 20
}
export enum eTiposClienteRest 
{ 
  WebERP = 1,
  WebDesktop = 2,
  AppMovil = 3,
  ErpRest = 4, 
  WebAdmin = 5,  
  ExternalApi = 6
}
export enum eStatusCode 
{
  Ok = 0,
  Error = 1,
  Fatal = 2 
}
export enum ePlatforms
{
  app='app',
  desk='desk',
  web='web'
}
export enum eAppPaths 
{  
  login = 'login',  
  homeEmpresa = 'empresa',
  homeCustomer= 'cliente',
  homeProvider= 'proveedor',
  homeUser='user',
  notificaciones = 'notificaciones',
  ticketid = 'ticketid',
  tclienteid='tclienteid',
  tickets = 'ticket',
  ticketsCliente = 'clientetickets',
  prodcutos2='prodcutoscs',
  productoId='productoid'
}
export enum eSqlTables
{
  AppUsuarios = 'AppUsuarios',
  AppUserDataSincronizacion = 'AppUserDataSincronizacion',
  AppSystemSettings = 'AppSystemSettings',
  /*Empleados = 'Empleados',
  EmpresaUsuarioApp = 'EmpresaUsuarioApp',
  EmpresaPermisos = 'EmpresaPermisos',
  PerfilMenu = 'PerfilMenu',
  Empresas = 'Empresas',  
  RolAppUsuario = 'RolAppUsuario', */
  LogError = 'LogError',
  RF='rf'
}
export enum ePermisos
{
  Agregar = 1,
  Editar = 2,
  Eliminar = 3,
  CostosPrecios = 4,
  Lectura = 5,
  Inventario = 6,
  FullRows = 7,
  PedAutCli = 8,
  TicketAut = 9
}
export enum eRolAppUsuario
{
  None = 0,
  Empleado = 1,
  Cliente = 2,
  Proveedor = 3
}
export enum eCommnadsToolbar
{
  reload ='reload',
  logof = 'logof',
  options = 'options'
}
export enum eTipoUsuarioTicket 
{ 
  AppUsuario = 1,
  AppEmpleado = 2
}
export enum eModoTicket 
{ 
  Solicitado = 1, 
  Asignado = 2
}
export enum eTicketStatus
{
    Abierto = 1,
    Pendiente = 2,
    Resuelto = 3,
    Cerrado = 4
}
export enum eTicketPrioridades
{
    Baja = 1,
    Media = 2,
    Alta = 3,
    Urgente = 4
}
export enum eTipoFile
{
    Image = 1,
    Pdf = 2,
    Other = 3
}
export enum ePermisosEmpresas
{
  TICKET_ALLOWADDPS = 'TICKET_ALLOWADDPS',
  TICKET_TIPO_NEW = 'TICKET_TIPO_NEW'
}
export const eSignalRMaxAttemps = 3;
export const eSignalRInitAttemp = 1;
export enum ePage
{
  ItemsPerPage = 10
}
export const JoinConcat='|';
export enum eTipoFiltros
{
  custom = "custom"
}
export enum eSettingsNames
{
  language="AppLanguageId",
  theme="AppThemeName"
}
export enum eThemesNames
{
  dark="dark_Default",
  light="light_Default"
}
export enum eDefaultLanguageApp
{
  laguageDefault = "es-mx"
}
export enum eTipoEmisorReceptor
{
  UsuarioEmpresa = 1,UsuarioApp = 2
} 
export enum eTipoNotificaciones
{
 NotificacionUsuario = 1,NotifiacionAppUsuario = 2
}
//import { BehaviorSubject } from "rxjs";
import { ChatService } from "@/services/ChatService";

export class MessageManagerService
{
    protected Services:any;
    constructor(services:any)
    {
        this.Services = services;
    }
    /*public GetInfoProcess(tipo: number, message: string): void
    {
        this.InfoProcess.next({tipo, message});
    }
    public GetInfoProcessMassive(tipo: number, message: string): void
    {
        this.InfoProcessMassive.next({tipo, message});
    }*/
    public ChatMessageRecived(parametros: any)
    {
        (this.Services.chat as ChatService).ChatMessageRecived(parametros);
    }
    public StatusChangeFromHub(parametros: any)
    {
        (this.Services.chat as ChatService).ContactStatusChange.next(parametros);
    }
    /*public NotificacionFromChat(tipo: number, message: string, msj: any)
    {
        this.Noti.push(msj.EmisorName + ':<br/>' + msj.DescriptionPush, ePushValues.Ok);
        this.notisService.AddNotificacion(msj.EmisorName, msj.Mensaje, msj.DescriptionPush, false);
    }
    public StatusChangeFromHub(tipo: number, message: string, parametros: any)
    {
        this.idxChat.ContactStatusChange.next(parametros);
    }
    public NotificacionFromQuestShop(tipo: number, message: string, msj: any)
    {
        this.Noti.push(msj.EmisorName + ':<br/>' + msj.DescriptionPush, ePushValues.Ok);
        this.notisService.AddNotificacion(msj.EmisorName, msj.Mensaje, msj.DescriptionPush, false);
    }
    public NotificacionFromPedidoFromApp(tipo: number, message: string, msj: any)
    {
        this.Noti.push(msj.EmisorName + ':<br/>' + msj.DescriptionPush, ePushValues.Ok);
        this.notisService.AddNotificacion(msj.EmisorName, msj.Mensaje, msj.DescriptionPush, false);
    }*/
}
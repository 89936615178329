import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import 'animate.css';
import PrimeVue from 'primevue/config';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
//import "primevue/resources/themes/lara-dark-purple/theme.css"
//import "primevue/resources/themes/lara-light-indigo/theme.css";

import 'primeicons/primeicons.css';
import FontAwesomeIcon from "@/fontawesome";
import Myi18n from './i18n';
import globalerror from './services/base/GlobalError';
import 'vue3-emoji-picker/css';
import {IonicVue} from '@ionic/vue';
import '@ionic/core/css/ionic.bundle.css';
import '@/assets/styles/site.css';

import '@/assets/styles/themes/default.css';
import '@/assets/styles/themes/dark_Default.css';

/*Los css agregados despues de 'site.css' tienen preferencia ya que estan despues del site.css por lo tanto son los estilos con mayor prioridad.*/
import '@/assets/styles/responsive/SiteDesk.css';
import '@/assets/styles/responsive/SiteDeskSmall.css';
import '@/assets/styles/responsive/SiteSmall.css';
import '@/assets/styles/responsive/SiteXtraSmall.css';







import ToastService from 'primevue/toastservice';
import 'alertifyjs/build/css/alertify.min.css'
import 'alertifyjs/build/css/themes/default.min.css';

import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import jsonLangEsDefault from '@/locales/es.json';
import BadgeDirective from 'primevue/badgedirective';

defineCustomElements(window);
const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(VueAxios, axios);
app.use(Myi18n);
app.use(PrimeVue,{locale:{...jsonLangEsDefault.prime}});
app.component("FontAwesomeIcon", FontAwesomeIcon)

app.use(ToastService);

app.use(DialogService);
app.use(ConfirmationService);
app.use(IonicVue);
app.directive('badge', BadgeDirective);
app.config.errorHandler = globalerror;
app.mount('#app');
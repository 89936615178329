import BaseService from "./base/BaseService";
export default class TestService extends BaseService
{
    constructor(param?: any)
    {
        super(param); 
    }    
    public async GetTest2(): Promise<Usuarios>
    {               
       return await this.Get('Test/Ping'/*,
       (r:any)=>{
        console.log('la response desde testservice');
        console.log(r);
       }
       ,(d:any)=>{return d? new Usuarios(d):null;}*/)     
      // .catch(()=>{ this.alertify.Error('algo salio mal')})//if removed errorHandler is raised
       //.finally(()=>{this.alertify.Sucess('la operacion termino');});       
    }  
    public canUserAccess(sesion: any,from : any): boolean
    {
        console.log(sesion);
        console.log(from);
        return sesion?.has;
    }  
}
export interface IUsuarios 
    {
        UsuarioId:number;
        NombreUsuario:string;
        Correo:string;
        EmailConfirmed:boolean;
        MotivoBloqueo:string
        getname():string
    }
export class Usuarios implements IUsuarios
    {
        constructor(data?: any)
        {
            Object.assign(this, data); // or set each prop individually
          }
        public UsuarioId = 0;
        public NombreUsuario = 'de ';
        public Correo = '';
        public EmailConfirmed = false;
        public MotivoBloqueo= ""
        public getname()
        {
            return this.UsuarioId + " " + this.MotivoBloqueo;
        }
    }
import { IConversacion } from '@/model/IConversacion';
import { IAppUsuarios } from '@/model/IAppUsuarios';
import { ISesionUsuarios } from '@/model/ISesionUsuarios';
import { IChatConversacion } from '@/model/chat/IChatConversacion';
import { eTipoEmisorReceptor } from '@/model/GloblasEnum';
export interface IEmpresaUsuarios
{
    EmpresaUsuarioId:number;
    EmpresaId:number;
    UsuarioId:number;
    Activo:boolean;	
    AppUsuarioId:number;
    Usuario:any;
}
export interface IEmpresaUsuarioApp
{
    EmpresaUsuarioAppId:number;	
    EmpresaId:number;	
    AppUsuarioId:number;	
    TipoRelacionId:number;	
    IdentificadorId:number;	
    Activo:boolean;
    AppUsuario:any;
}
export interface IChatContact
{
    EmpresaUsuario:IEmpresaUsuarios|null;
    EmpresaUsuarioApp:IEmpresaUsuarioApp|null;
    Tipo:number;
    Id:number
    IdApp:number
    IsApp:boolean
    SesionUsuarios:ISesionUsuarios|null
    UsuarioDetalle:any
    AppUsuarios:IAppUsuarios|null
    Status:number
    LastConectionInfo:string
    EmpresaId:number
    LastMessage:IConversacion|null
    TipoRelacionId:number,    
    Chat: IChatConversacion|null,
    getCountNewMessages: number,
    getFullName:string,
    inicializaChat(): void
}
export class ChatContact implements IChatContact
{    
    public SesionUsuarios:ISesionUsuarios|null = null;
    public UsuarioDetalle:any = null;
    public AppUsuarios:IAppUsuarios|null = null;
    public Status = 0;
    public LastConectionInfo = '';
    public EmpresaId = 0;
    public LastMessage:IConversacion|null=null;
    public TipoRelacionId = 0;    
    public Chat: IChatConversacion|null = null;
    public EmpresaUsuario:IEmpresaUsuarios|null = null;
    public EmpresaUsuarioApp:IEmpresaUsuarioApp|null = null;
    public Tipo = 0 ;    
    constructor(data?: any)
    {
        Object.assign(this, data);        
    }    
    public get getFullName ():string
    {        
        if (!this.IsApp)
        {
            const detalle = this.EmpresaUsuario?.Usuario.Detalle.Empleado? this.EmpresaUsuario.Usuario.Detalle.Empleado : this.EmpresaUsuario?.Usuario.Detalle;        
            return `${detalle.Nombre} ${detalle.Paterno}${(detalle.Materno ? ' '+ detalle.Materno : '')}`;          
        }
        else
        {
            return `${this.EmpresaUsuarioApp?.AppUsuario.Nombre}${(this.EmpresaUsuarioApp?.AppUsuario.Apellidos ? ' ' + this.EmpresaUsuarioApp.AppUsuario.Apellidos : '')}`;
        }
    }
    public get IsApp():boolean
    {
      return this.Tipo === eTipoEmisorReceptor.UsuarioApp;
    }
    public get Id():number
    {
        if (this.EmpresaUsuario) return this.EmpresaUsuario? this.EmpresaUsuario.EmpresaUsuarioId : 0;
        if (this.EmpresaUsuarioApp ) return this.EmpresaUsuarioApp? this.EmpresaUsuarioApp.EmpresaUsuarioAppId : 0;
        return 0;
    }
    public get IdApp():number
    {
        if (!this.IsApp) return this.EmpresaUsuario? this.EmpresaUsuario.UsuarioId:0;
        if (this.IsApp ) return this.EmpresaUsuarioApp? this.EmpresaUsuarioApp.AppUsuarioId:0;
        return 0;
    }
    public get getCountNewMessages () 
    {
        return this.Chat && this.Chat.NewMessage ? this.Chat.NewMessage : 0;
    }
    public inicializaChat(): void
    {
        this.Chat =  {      
            PppageRows : 30,
            PppageId : 1,
            TotalRows : 0,
            CurrentRows: 0,
            NewMessage: 0,
            MensajesRestantes: null,
            Mensajes : [],
            UserIdFrom:0, 
            UserIdTo:0,
            TipoEmisor:0,
            TipoReceptor:0,                        
            Chat:[]
        };    
  }
}
import { ToastServiceMethods } from "primevue/toastservice";
import IAlertifyService from "../../model/services/IAlertifyService";
import { useToast } from 'primevue/usetoast';
import { ToastMessageOptions } from "primevue/toast";
// eslint-disable-next-line no-var
declare var alertify: any;
export default class AlertifyVueService implements IAlertifyService
{  
  protected toast:ToastServiceMethods;
  constructor()
  {
    this.toast = useToast();
  }
  public Sucess(msg:string)
  {
      this.toast.add(this.getMessage('success','',msg));
  }
  public Error(msg:string)
  {
      this.toast.add(this.getMessage('error','',msg));
  }    
  protected getMessage(severity:'success' | 'info' | 'warn' | 'error' | undefined,title:string,msg:string):ToastMessageOptions
  {
      return { 
          severity:severity, 
          summary:title, 
          detail:msg,life: 6000 };
  }
  public async Prompt(title: string, body: string, val: string,buttons:string[], fnOk:any, fnCancel:any):Promise<void>
  {
    const a = buttons[0];
    const c = buttons[1];
    const p = alertify.prompt(title, body, val, (evt: any, value: any)=>{ fnOk(value)}, ()=>{fnCancel()}).set('labels', { ok: a, cancel: c });
    if (fnCancel)
    {
      const cancelButton = p.elements.footer.querySelector('button.ajs-cancel');
      cancelButton.style.display = "none";        
    }                  
  }
}
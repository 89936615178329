import IDataBaseService from "@/model/services/IDataBaseService";
import BaseService from "./base/BaseService";
import { AppSystemSettings, IAppSystemSettings } from "@/model/IAppSystemSettings";
import { eDefaultLanguageApp, eSettingsNames, eThemesNames } from "@/model/GloblasEnum";
import { useMainStore } from "@/store/main-store";

export default class AppSystemSettingsService extends BaseService
{  
  protected DBServie :IDataBaseService | undefined;     
  constructor(param?: any,dbService?: IDataBaseService)
  {
    super(param);            
    this.DBServie = dbService;   
  }  
  public async SaveAppSettings(settings:IAppSystemSettings[]):Promise<void>
  { 
    await this.DBServie?.setSystemSettings(settings);  
  }
  public async LoadAppSettings():Promise<IAppSystemSettings[]>
  {        
    const settings:IAppSystemSettings[] = [];
    const dbSettings = await this.DBServie?.getSystemSettings();    
    if(!dbSettings || dbSettings.length <= 0)
    {
      settings.push({Id:null,Setting:eSettingsNames.language, Value:eDefaultLanguageApp.laguageDefault, Activo:true});            
      settings.push({Id:null,Setting:eSettingsNames.theme, Value:eThemesNames.light , Activo:true});            
    }
    else
    {
      for (const setting of dbSettings) {
        settings.push(setting);
      }
    }
    return settings;
  }
  public getSettingByName(settingName: string):IAppSystemSettings
  {    
    const appSystemSettings = this.getSettingsFromPinia();
    let setting:IAppSystemSettings|undefined = appSystemSettings.find((x:IAppSystemSettings) => x.Setting === settingName);
    if(!setting)
    {
      setting = new AppSystemSettings();
    }      
    return setting;
  }
  protected getSettingsFromPinia():IAppSystemSettings[]
  {
    return useMainStore().getAppSettings;
  }
}
export interface IServiceResponse
{
    StatusCode:number;
    StatusDescription:string;
    Status:number;
    hasMessage:boolean;
    Messages:any;
    UserMessage:string;
    Response:any;
    FileName:string;
    OtherResponse:string;
    ContentFail:string;
    getResponse():any;
    //cloneMin(clone: IServiceResponse):void;
}
export class ServiceResponse implements IServiceResponse
{
    constructor(data?: any)
    {
        Object.assign(this, data);        
    }
    public StatusCode = 0;
    public StatusDescription='';
    public Status = 0;
    public hasMessage = false;
    public Messages=null;
    public UserMessage="";
    public Response = null;
    public FileName = '';
    public OtherResponse='';
    public ContentFail='';
    public getResponse():any
    {
        return this.Response;
    }
    /*public cloneMin(clone: IServiceResponse):void
    {
        this.StatusCode = clone.StatusCode;
        this.StatusDescription= clone.StatusDescription;
        this.Status = clone.Status;
        this.hasMessage = clone.hasMessage;
        this.Messages=clone.Messages;
        this.UserMessage = clone.UserMessage;
        this.Response = null;
        this.FileName = clone.FileName;
        this.OtherResponse=clone.OtherResponse;
        this.ContentFail=clone.ContentFail;
    }*/
}
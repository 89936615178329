import { IEmpresaUsuarioApp, IEmpresaUsuarios } from "./chat/IChatContact";
import { eRolAppUsuario } from "./GloblasEnum";

export interface IAppUserDataSincronizacion
{
    AppUsuarioId:number;
    IsEmpleado:boolean;
    /**Id de la empresa a la que pertenece el empleado. */
    EmpresaEmpleadoId:number;
    IsRoot:boolean;
    Empleado: any;
    FechaSincronizacion:any;
    /**Las empresas con las cuales el UsuarioApp tiene relacion. */
    EmpresaUsuarioApp:Array<IEmpresaUsuarioApp>|null;
    /** Cuando es empleado obtenemos la relacion con la empresa en la cual esta dado de alta como empleado. */
    EmpresaUsuario:IEmpresaUsuarios|null;
    EmpresaModulos:Array<any>|null;
    EmpresaParametros:Array<any>|null;
    PerfilMenu:Array<any>|null;
    /**Datos de las empresas con las cuales el UsuarioApp tiene relacion. */
    Empresas:any;
    RolAppUsuario:Array<number>|null;
    SignalRActive: boolean, 
    getPerfil():any;
    isProveedor():boolean;
    isCliente():boolean;
    syncValid():boolean;
    getEmpresaById(empresaId : number):any;
    getEmpresaName(empresa:any):string;
    getEmpresaByUserApp(empresaId: number):IEmpresaUsuarioApp|null;
    getUsuarioId():number
}
export class AppUserDataSincronizacion implements IAppUserDataSincronizacion
{
    constructor(data?: any)
    {
       Object.assign(this, data);        
    }
    public AppUsuarioId = 0;
    public IsEmpleado = false;
    /**Id de la empresa a la que pertenece el empleado. */
    public EmpresaEmpleadoId = 0;
    public IsRoot = false;
    public Empleado:any = null;
     /**Las empresas con las cuales el UsuarioApp tiene relacion. */
    public EmpresaUsuarioApp = null;
    /** Cuando es empleado obtenemos la relacion con la empresa en la cual esta dado de alta como empleado. */
    public EmpresaUsuario = null;
    public EmpresaModulos = null;
    public EmpresaParametros = null;
    public PerfilMenu = null;
    /**Datos de las empresas con las cuales el UsuarioApp tiene relacion. */
    public Empresas = null;
    public RolAppUsuario = null;
    public FechaSincronizacion = null;
    public SignalRActive = false;
    public getPerfil()
    {
        return this.Empleado && this.Empleado.Usuario && this.Empleado.Usuario.Perfiles &&
               this.Empleado.Usuario.Perfiles.length > 0 ? this.Empleado.Usuario.Perfiles[0]:null;
    }
    public getUsuarioId():number
    {
        return this.Empleado && this.Empleado.Usuario && this.Empleado.Usuario.UsuarioId? this.Empleado.Usuario.UsuarioId :0 ;
    }
    public isProveedor():boolean
    {        
        return this.RolAppUsuario? (this.RolAppUsuario as Array<number>).includes(eRolAppUsuario.Proveedor):false;
    }
    public isCliente():boolean
    {
        return this.RolAppUsuario? (this.RolAppUsuario as Array<number>).includes(eRolAppUsuario.Cliente):false;
    }
    public syncValid():boolean
    {
        return this.AppUsuarioId > 0? true:false;
    }
    public getEmpresaById(empresaId : number): any
    {
        if(empresaId && this.Empresas)
            return (this.Empresas as Array<any>).find((x:any) => x.EmpresaId === empresaId );
        return null;
    }
    public getEmpresaName(empresa:any)
    {
        if(empresa)
            return empresa.Empresa;
        else
            return '';
    }
    public getEmpresaByUserApp(empresaId: number):IEmpresaUsuarioApp|null
    {        
        if(this.EmpresaUsuarioApp && (this.EmpresaUsuarioApp as Array<IEmpresaUsuarioApp>).length > 0 && this.AppUsuarioId)
        {
            const res = (this.EmpresaUsuarioApp as Array<IEmpresaUsuarioApp>).find((x:IEmpresaUsuarioApp)=> x.EmpresaId === empresaId && x.AppUsuarioId === this.AppUsuarioId);
            return res? res:null;
        }
        return null;            
    }
}
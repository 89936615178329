import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone, faUser, faFlag,faWandMagicSparkles,
        faTriangleExclamation,faArrowUp, faSmile,
        faCubes,faTicket, faPaperclip, faPaperPlane,
        faTrash,faFaceFrown,faDownload,
        faCamera, faImage, faPlusCircle,faPlus,faTimes,faTimesCircle ,
        faMessage, faCircleCheck,faChevronDown,faFile,
        faFileExcel, faUserTag,faFilePdf,faQrcode,faIdCardAlt,
        faUserPlus,faUserMinus,faTimesRectangle,faTimesSquare,faFileDownload,faSave} from "@fortawesome/free-solid-svg-icons";

library.add(faPhone, faUser, faFlag,faWandMagicSparkles, faTriangleExclamation, faArrowUp, faSmile,
            faCubes,faTicket, faPaperclip,faPaperPlane, faTrash,faFaceFrown,faDownload, faCamera,
            faImage,faPlusCircle,faPlus,faTimes,faTimesCircle,faMessage,faCircleCheck,faChevronDown,
        faFile,faFileExcel,faUserTag,faFilePdf,faQrcode,faIdCardAlt,faUserPlus,faUserMinus,faTimesRectangle,faTimesSquare,
        faFileDownload,faSave);

export default FontAwesomeIcon;
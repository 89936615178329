export interface IAppSystemSettings
{
    Id:number|null;
    Setting:string;
    Value:string; 
    Activo:boolean;
}
export class AppSystemSettings implements IAppSystemSettings
{
    public Id = 0;
    public Setting= '';
    public Value = ''; 
    public Activo = false;
}

import { ePermisos } from "@/model/GloblasEnum";
import { ISesionApp } from "@/model/ISesionApp";
import AppUIManagerService from "@/services/ui/AppUIManagerService";
export default class SecurityManagerService 
{    
    protected appUIManagerService:AppUIManagerService|undefined; 
    protected param:any;
    constructor(params?:any, uiManager?: AppUIManagerService, )
    {
        this.appUIManagerService = uiManager;
        this.param = params;
    }
    public getListPermisosForCurrent(sesion:ISesionApp):any[]|undefined
    {        
       const menu = this.appUIManagerService?.getActiveMenu;
       if(menu && sesion)
       {         
         if(sesion.SyncData && sesion.SyncData.PerfilMenu)
         {
            if(sesion.SyncData.IsRoot)
            {
                return this.builPermisosRoot();
            }
            else
            {
                const permisos = sesion.SyncData.PerfilMenu.filter((x:any) => x.MenuNombre.toString().localeCompare(menu.data.menuNombre) === 0);
                return permisos;
            }
         }
       }
       return undefined;
    }
    protected builPermisosRoot():any[]
    {
        const all = [];
        all.push(this.buildPermiso(ePermisos.Agregar));
        all.push(this.buildPermiso(ePermisos.CostosPrecios));
        all.push(this.buildPermiso(ePermisos.Editar));
        all.push(this.buildPermiso(ePermisos.Eliminar));
        all.push(this.buildPermiso(ePermisos.FullRows));
        all.push(this.buildPermiso(ePermisos.Inventario));
        all.push(this.buildPermiso(ePermisos.Lectura));
        all.push(this.buildPermiso(ePermisos.PedAutCli));
        all.push(this.buildPermiso(ePermisos.TicketAut));
        return all;
    }
    protected buildPermiso(permiso:number)
    {
        return {
            PerfilMenuId: 0,
            PerfilId: 0,
            MenuId: 0,
            PermisoMenuId: permiso,
            Activo: true,
            Nombre: "",
            Titulo: "",
            MenuNombre: "",
            PreId: 0,
            MetaInfo: null,
            Borrar: false
        };
    }
    public getMenusBySession(sesion:ISesionApp|null):any[]
    {
        if(sesion && sesion.SyncData && sesion.SyncData.IsEmpleado)
        {            
            return require('@/assets/menus/empresaMenu.json');    
        }
        else if(sesion && sesion.SyncData && sesion.SyncData.isCliente())
        {
            return  require('@/assets/menus/clienteMenu.json');
        }
        else if(sesion && sesion.SyncData && sesion.SyncData.isProveedor())
        {
            return require('@/assets/menus/proveedorMenu.json');
        }
        else
        {
            return require('@/assets/menus/appUserMenu.json');
        }
    }
    public getMenuByName2(sesion:ISesionApp|null,menuName:string):any|null
    {
        if(!sesion) return null;
        const menus = this.getMenusBySession(sesion);
        return this.getMenuByName(menuName,menus);
    }
    public getMenuByName(menuName:string,menus:any[]):any|null
    {
        if(!menuName || !menus || menus.length <= 0) return null;
        for (const menu of menus) 
        {
            if(menu.data && menu.data.url)
            {
                if(menu.data.url.toString().localeCompare(menuName) === 0 || this.searchOnUrlRel(menuName,menu.data.urlRel))
                {
                    return menu;
                }
            }
            if(menu.children && menu.children.length > 0)
            {
                const find = this.getMenuByName(menuName,menu.children);
                if(find)
                    return find;
            }
        }
    }
    protected searchOnUrlRel(menuName:string,rel:string)
    {
        if(!rel) return false;
        const all = rel.toString().split(',');
        const find = all.find((x:string)=> x.localeCompare(menuName) === 0);
        return find;
    }
}
import AppUIManagerService from './ui/AppUIManagerService';



import BarCodeReaderWebComponent from '@/components/shared/BarCode/BarCodeReaderWebComponent.vue';

export const CodeReaderName = 'dv-code-reader-container';
export interface BarCodeResult {
  text:string,
  format:string,
  result:any
}
export default class BarCodeService
{  
  protected readonly appUIManagerService:AppUIManagerService;
  protected appConfig: any;
  protected readonly classBody = 'barcode-scanner-active';
  protected readonly classModalReader = 'barcode-scanner-modal'
  protected readonly classModalReaderHide = 'barcode-scanner-modal-hide';
  constructor(appUIManagerService:AppUIManagerService,param?: any)
  {              
    this.appConfig = param.appconfig;
    this.appUIManagerService = appUIManagerService;
  }
  protected showCodeReader()
  {
    document.querySelector('body')?.classList.add(this.classBody);
    const modal = document.getElementById(CodeReaderName);
    if(modal)
    {
      modal.classList.add(this.classModalReader);
      modal.classList.remove(this.classModalReaderHide);
    }
  }
  public async closeCodeReader()
  {    
    document.querySelector('body')?.classList.remove(this.classBody);
    const modal = document.getElementById(CodeReaderName);
    if(modal)
    {
      modal.classList.remove(this.classModalReader);
      modal.classList.add(this.classModalReaderHide);
    }
    await BarcodeScanner.stopScan();
  }
  public async startScan(options?:any ,onScaned?:any)
  {



    await this.startScanWeb(options,onScaned);

  }
  public async startScanWeb(options?:any ,onScaned?:any)
  {        
    const title = options && options.title? options.title:'BarCode Reader';
    await this.appUIManagerService.showModal(BarCodeReaderWebComponent,{data:options},title,(result:any) => 
    { 
      if(onScaned && result && result.data)
      {                
        onScaned(result.data);
      }  
    });
  }
  public async startScanApp(options?:any ,onScaned?:any)
  {
    if(options)
    {
      if(options.helpText)
      {
        const help = document.getElementsByClassName('s-dv-instruccions-scaner-help');
        if(help)
        {
          help[0].innerHTML = options.helpText;
        }
      }
      if(options.title)
      {
        const title = document.getElementById('dv-barcodereader-app-title');
        if(title)
        {
          title.innerHTML = options.title;
        }
      }
    }
    this.showCodeReader();    
    const listener = await BarcodeScanner.addListener('barcodeScanned', async result => 
    {
      listener.remove();  
      this.closeCodeReader();
      if(onScaned)
      {
        if(result && result.barcode && result.barcode.displayValue)
        {
          const barResult:BarCodeResult = { text:result.barcode.displayValue,format:result.barcode.format,result:result.barcode }
          onScaned(barResult);
        }        
      }      
    });
    
    if(!options)
      await BarcodeScanner.startScan().catch(async ()=> await this.closeCodeReader());
    else
      await BarcodeScanner.startScan(options).catch(async ()=> await this.closeCodeReader());
  }
}
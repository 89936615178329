import { AppUserDataSincronizacion, IAppUserDataSincronizacion } from "@/model/IAppUserDataSincronizacion";
import BaseService from "./base/BaseService";
import { ISesionApp } from "@/model/ISesionApp";
import { AppUsuarios, IAppUsuarios } from "@/model/IAppUsuarios";
import LoginService from "./LoginService";
import { BehaviorSubject } from "rxjs";
import { IServiceResponse, ServiceResponse } from "@/model/IServiceResponse";
export default class SynLocalDBService extends BaseService
{    
    public updateProfileResult: BehaviorSubject<boolean>;
    constructor(param?: any)
    {
      super(param);
      this.updateProfileResult = new BehaviorSubject<any>(null);            
    }
    public async SincronizaInformacionAsyn(): Promise<IAppUserDataSincronizacion>
    {        
      let error = false;
      const sesion:ISesionApp = await this.baseGetSesion();
      let data: IAppUserDataSincronizacion|null=null;   
      if(sesion)
      {
        data = new AppUserDataSincronizacion();
        data.FechaSincronizacion = new Date();
        data.AppUsuarioId = sesion.User?.AppUsuarioId? sesion.User?.AppUsuarioId : 0;
      }
      const response: IAppUserDataSincronizacion =  await this.Post('IndexSyncAppUsuario/AppUsuarioSincronizacion',
      data,
      null,
      /*transform*/
      (t:any)=>
      {
          return t? new AppUserDataSincronizacion(t):null;
      },()=>
      {
        error = true;        
      },true,false);      
      return !error? response:new AppUserDataSincronizacion();       
    }
    public async SincronizaInformacionAsynUI(mainStore: any,service: LoginService|any, user: IAppUsuarios,getAllInfoEmpresas:boolean): Promise<IAppUserDataSincronizacion>
    {
      if(user && user.AppUsuarioId > 0) 
      {
        const sync:IAppUserDataSincronizacion = await this.SincronizaInformacionAsyn();
        if(sync && sync.syncValid())
        {                  
          await this.UpdateLocalEmpresas(sync,getAllInfoEmpresas);          
          await service.registerSyncInfo(sync);          
          mainStore.setSesionApp(service.ToPinia(user,sync));
          service.VerifyEmpresa(mainStore,sync);         
        }
        return sync;
      }
      return new AppUserDataSincronizacion(); 
    }
    /*Profile Component */
    public async ActualizaAsync(appUsuarios: IAppUsuarios):Promise<IAppUsuarios>
    {      
      let ok = false;
      const respAux = await this.Post("IndexUserProfile/ActualizaUsuario",appUsuarios,null,
      /*transform*/
      (t:any)=>
      {
        return t? new AppUsuarios(t):null;
      },()=>{ ok = false},true,true).finally(()=>
      {                
        this.updateProfileResult.next(ok);
      });   
      return ok? respAux:null;
    }    
    public HasWarning(user:IAppUsuarios):boolean
    {
      if (user.EmailConfirmed) return false;
      return true;
    }
    public async CanSendLocalStorage():Promise<IServiceResponse>
    {
      let resp = null;
      resp = await this.Get("IndexAppSupport/CanSendLocalStorage",null,null,null,true,false);        
      return resp;
    }
    public async SendLocalStorage(AppUsuarioId:number,db:any,sended:string,nodb:string):Promise<string>
    {
      let resp = '';
      const response = await this.CanSendLocalStorage();      
      if (response && response.Response <= 0)
      {        
        if (db && db.content)
        {
          const url = "IndexAppSupport/SendLocalStorage";
          let support = null;
          if(db.tipo === 1) //desk
            support = { Response: `${AppUsuarioId}_${db.name}`, Support : db.content};  
          if(db.tipo === 2) //app
            support = { Response: `${AppUsuarioId}_${db.name}`, SupportB64 : db.content};  
          let serviceResponse:IServiceResponse = new ServiceResponse();                           
          serviceResponse = await this.Post(url, support,null,null,null,false,false);
          if(serviceResponse && serviceResponse.Response)
          {
            resp = serviceResponse.Response.Response;
          }
        } 
        else
        {
          resp = nodb;
        }       
      }
      else
      {
        resp = sended;
      }      
      return resp ? resp:'';
    }
    protected async UpdateLocalEmpresas(sync:IAppUserDataSincronizacion,forceAll:boolean):Promise<void>
    {                
      if (sync && sync.Empresas && sync.Empresas.length > 0)
      {        
        const verificar = new Array<any>();
        for (const empresa of sync.Empresas)
        {
          verificar.push({ EmpresaId:empresa.EmpresaId, Version: forceAll? -1 : empresa.Version });
        }      
        const respAux:IServiceResponse = await this.Post("IndexSyncAppUsuario/UpdateLocalEmpresas",verificar,null,null,()=>{this.showError()},true,false);              
        if (respAux && respAux.Response)
        {
          for (const empresa of respAux.Response)
          {
            const existe = sync.Empresas.findIndex((ex: any) => ex.EmpresaId === empresa.EmpresaId);
            if (existe >= 0)
            {
              sync.Empresas[existe] = {...empresa};
            }
          }                                
        }
      }
    }
    public async UserProfileSettings(UsuarioDetalle: any):Promise<IServiceResponse>
    {              
      let serviceResponse:IServiceResponse = new ServiceResponse();                                             
      if(!UsuarioDetalle) return serviceResponse;      
      serviceResponse = await this.Post('UsuariosERP/UserProfileSettings', UsuarioDetalle,null,null,null,true,undefined,null);
      return serviceResponse;
    }
}//export ends
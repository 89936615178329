/* eslint-disable @typescript-eslint/no-empty-function */
import { eSqlTables } from "@/model/GloblasEnum";
import { IAppSystemSettings } from "@/model/IAppSystemSettings";
import { AppUserDataSincronizacion, IAppUserDataSincronizacion } from "@/model/IAppUserDataSincronizacion";
import { AppUsuarios, IAppUsuarios } from "@/model/IAppUsuarios";
import IDataBaseService from "@/model/services/IDataBaseService";
import CService from "@/services/CService";
import localforage from "localforage";

export default class DataBaseWebService implements IDataBaseService
{     
  private cService:CService|null = null;  
  constructor(cripto: CService)
  {
    this.cService = cripto;
  }  
  public async Init():Promise<void>{}
  public async Close():Promise<void>{}    
  public async SaveAsync(name:string,entity:any):Promise<any>
  {
    await this.setStoreElement(name,this.cService?.Encrypt(JSON.stringify(entity))); 
    return entity;
  }
  public async GetAsync(name:string):Promise<any>
  {
    let val:string|null|undefined = await this.getStoreElement(name);
    val = val? this.cService?.Decrypt(val):'';
    val = val? JSON.parse(val):null;
    return val;
  }
  public async CleanRepo(table:string)
  {
    await this.setStoreElement(table,'');
  }
  public async GetSingleAsync(name:string):Promise<any>
  {
    return await this.GetAsync(name);
  }
  public async GetAllAsync(name:string):Promise<any>
  {
    return await this.GetAsync(name);
  }
  protected async getStoreElement(name: string):Promise<string|null>
  {    
    return await localforage.getItem<string>(name);                 
  }
  public async getSessionDB():Promise<IAppUsuarios|null>
  {        
    const result = await this.GetSingleAsync(eSqlTables.AppUsuarios);
    const sesion = result? new AppUsuarios(result):null;
    return sesion;
  }
  public async registerSesion(userApp :IAppUsuarios):Promise<any>
  {
    await this.CleanRepo(eSqlTables.AppUsuarios);
    return await this.SaveAsync(eSqlTables.AppUsuarios,userApp);
  } 
  protected async setStoreElement(name: string, content?: string):Promise<string|null|undefined>
  {    
    await localforage.setItem(name, content);
    return content;                 
  }
  public async registerSyncInfo(sync:IAppUserDataSincronizacion):Promise<void>
  {
    await this.CleanRepo(eSqlTables.AppUserDataSincronizacion);    
    await this.SaveAsync(eSqlTables.AppUserDataSincronizacion, sync);    
  }
  public async getSyncInfo():Promise<IAppUserDataSincronizacion>
  {
    let sync:IAppUserDataSincronizacion = new AppUserDataSincronizacion();
    const header = await this.GetSingleAsync(eSqlTables.AppUserDataSincronizacion);
    if(header)
    {
      sync = new AppUserDataSincronizacion(header);
    }    
    return sync;
  }  
  public async closeSesion():Promise<void>
  {
    await this.CleanRepo(eSqlTables.AppUsuarios);  
    await this.CleanRepo(eSqlTables.AppUserDataSincronizacion);
  }
  public async getFlagRegistro():Promise<any>
  {
    const flag = await this.GetSingleAsync(eSqlTables.RF);
    return flag;
  }
  public async removeFlagRegistro():Promise<void>
  {
    await this.CleanRepo(eSqlTables.RF); 
  }
  public async setFlagRegistro(id:number):Promise<void>
  {
    await this.SaveAsync(eSqlTables.RF, this.cService?.Encrypt( id + '')); 
  }
  public async getSystemSettings():Promise<IAppSystemSettings[]>
  {
    return await this.GetAsync(eSqlTables.AppSystemSettings);
  }
  public async setSystemSettings(settings: IAppSystemSettings[]):Promise<void>
  {
    await this.SaveAsync(eSqlTables.AppSystemSettings,settings);
  }
  public async getDataBaseByte():Promise<any>{}
}
import { AppUsuarios, IAppUsuarios } from "@/model/IAppUsuarios";
import BaseService from "./base/BaseService";
import { eStatusCode } from "@/model/GloblasEnum";
import IDataBaseService from "@/model/services/IDataBaseService";
import { ISesionApp, SesionApp } from "@/model/ISesionApp";
import { IAppUserDataSincronizacion } from "@/model/IAppUserDataSincronizacion";

export default class LoginService extends BaseService
{
  protected sPhoneR = "1111111111";
  protected spasR = "123456";
  protected DBServie :IDataBaseService | undefined;     
  constructor(param?: any,dbService?: IDataBaseService)
  {
    super(param);            
    this.DBServie = dbService;
  } 
  public isFreePath(to:any, freePaths:any): boolean
  {           
    const isFree = freePaths.find((c:any) => c === to.name);      
    return isFree? true:false;      
  }       
  public canUserAccess(sesion: ISesionApp|null,to:any,RoutesRol:any): boolean
  {                 
    const withRol = RoutesRol.find((c:any) => c.name === to.name);
    if(withRol)
    {
      if(!sesion || !sesion.SyncData || !sesion.SyncData.RolAppUsuario)  
      return false;
      let hasRol = false;
      for (const rolMenu of withRol.Rol)
      {
          for (const rolSesion of sesion.SyncData.RolAppUsuario)
          {
              if(rolMenu === rolSesion)
              {
                  hasRol = true;                    
                  break;
              }            
          } 
      }       
      return hasRol && sesion.canAccess();
    }
    else
    {
      return sesion? sesion.canAccess() : false;
    }
  }       
  public async Login(user: any,fnEval:any): Promise<AppUsuarios>
  {            
      let loginOk = false;
      const factory = this.getFactory();
      const cservice = factory.getCService;        
      const usr = { Correo : user.userId,Clave : cservice.Encrypt(user.clave)};
      const userApp:AppUsuarios =  await this.Post('IndexRegistroApp/AuthUserApp',usr        
      ,null,
      /*transform*/
      (t:any)=>
      {
          return t? new AppUsuarios(t):null;
      },null,true)        
      .finally(() =>
      {                        
        const resp = this.RequestResponse;            
        if(resp && resp.StatusCode === 0)
        {
          loginOk= true;
          fnEval(this.getInfoResponse());
        }
        else if(resp)
        {
          fnEval({tipo: eStatusCode.Error,msg: resp.Message});
        }
        if(!resp)
        {
          this.showResponse(true,true);
        }            
      });
      if(loginOk)
      {
        await this.registerSesion(userApp);            
      }
      return loginOk? userApp:new AppUsuarios();        
  }
  public async updateSesion(userApp :IAppUsuarios)
  {        
    await this.DBServie?.registerSesion(userApp);        
  }
  public async registerAfter(userApp :IAppUsuarios)
  {        
    await this.DBServie?.registerSesion(userApp);        
  }
  protected async registerSesion(userApp :IAppUsuarios)
  {        
    await this.DBServie?.registerSesion(userApp);        
  }
  protected getSesionAppObject(userApp :IAppUsuarios):ISesionApp
  {
    const factory = this.getFactory();
    const cservice = factory.getCService;
    const sesionApp = new SesionApp();
    sesionApp.Active = true;
    sesionApp.AppUsuarioIdString = cservice?.Encrypt(userApp.AppUsuarioId.toString());
    sesionApp.IdEmpresa = '';
    sesionApp.IdUsuario = '';
    sesionApp.User = userApp;
    sesionApp.HubConnection = null;
    return sesionApp;
  }
  protected async ToSesionApp(userApp :IAppUsuarios):Promise<ISesionApp>
  {        
    const sesionApp = this.getSesionAppObject(userApp);    
    const sync = await this.DBServie?.getSyncInfo();
    if(sync)
    {
      const factory = this.getFactory();
      const cservice = factory.getCService;
      sesionApp.SyncData = sync;
      sesionApp.IdEmpresa = cservice?.Encrypt(sync.EmpresaEmpleadoId.toString());
      if(sync.Empleado && sync.Empleado.Usuario && sync.Empleado.Usuario.UsuarioId)
      {
        sesionApp.IdUsuario = cservice?.Encrypt(sync.Empleado.Usuario.UsuarioId.toString());
      }
    }
    return sesionApp;
  }    
  public ToPinia(userApp :IAppUsuarios,sync:IAppUserDataSincronizacion)
  {
    const sesionApp = this.getSesionAppObject(userApp);
    sesionApp.SyncData = sync;
    return sesionApp;
  }       
  public async getSession():Promise<ISesionApp|null>
  {        
    let sesion:IAppUsuarios|null|undefined = null;
    sesion = await this.getSessionDB();  
    return sesion? this.ToSesionApp(sesion):null;
  }     
  protected async getSessionDB():Promise<IAppUsuarios|null|undefined>
  {        
    return await this.DBServie?.getSessionDB();
  }
  public async registerSyncInfo(sync:IAppUserDataSincronizacion)
  {
    await this.DBServie?.registerSyncInfo(sync);
  }
  public async CloseSesion(sesion: ISesionApp)
  {
    await this.DBServie?.closeSesion();
    await this.LogOffRest(sesion);
  }
  public async LogOffRest(sesion: ISesionApp)
  {
    if(!sesion || !sesion.User) return;
    if(sesion.SyncData.IsEmpleado)    
    {
      const modelUser = {EmpresaId: sesion.SyncData.EmpresaEmpleadoId ,
                         AppUsuarioId:sesion.User.AppUsuarioId,
                         UsuarioId: sesion.SyncData.Empleado.Usuario.UsuarioId};
      await this.Post("IndexRegistroApp/KillSessionApp",modelUser,null,null,null,true,undefined);
    }
    else
    {
      const modelUser = {EmpresaId: 0 ,
        AppUsuarioId:sesion.User.AppUsuarioId,
        UsuarioId: 0};
      await this.Post("IndexRegistroApp/KillSessionApp",modelUser,null,null,null,true,undefined);
    }    
  }
  public async getDataBaseByte()
  {
    return await this.DBServie?.getDataBaseByte();      
  }
  public IsDeleteRegisterFlag(e: string,c:string):boolean
  {    
    const comp = e === this.sPhoneR && c === this.spasR;   
    return comp;
  }
  public VerifyEmpresa(mainStore: any,sync:IAppUserDataSincronizacion):void
  {
    if(sync.IsEmpleado)
    {
      const empresa = sync.getEmpresaById(sync.EmpresaEmpleadoId);
      mainStore.setEmpresa(empresa);
    }  
  }
}
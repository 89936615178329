import { toDate } from '@/services/ui/GlobalFunctions';
export interface ITicketPrioridad
{
    TicketPrioridadId:number;
    Prioridad:string;
    PrioridadCve:string;
    Color:string;
    Activo:boolean;
}
export interface ITicketEstatus
{
    TicketEstatusId:number;
    Estatus:string;
    EstatusCve:string;    
    Activo:boolean;
}
export interface ITicketClasificacion
{
    TicketClasificacionId:number;
    Clasificacion:string;
    Descripcion:string;    
    Activo:boolean;
}
export interface IDocumentoEvidencia 
{
    DocumentoEvidenciaId :number;
    DocumentoId:number;
    Tabla :string;
    Documento :string;
    Miniatura :number;
    Activo :boolean;
    Content:any;
    Content64:string;
    Tipo :string;
    Borrar:boolean;
}
export interface ITicketInventario
{
    TicketInventarioId:number;
    TicketId :number;
    ProductoId:number|null;
    ServicioId:number|null;
    Cantidad:number|null;
    Activo:boolean;
    IsProducto:boolean;
    Producto:any|null;
    Servicio:any|null;
    Borrar:boolean;
}
export class Ticket
{    
    public TicketId = 0;
    public Folio='';  
    public Interno=false;   
    public EmpleadoId:number|null = null;    
    public TicketPrioridadId= 0;    
    public TicketEstatusId= 0;    
    public Nota='';  
    public NotaInterna='';  
    public OrigenReporteId= 0;
    public Activo=false;   
    public FechaRegistro:any;    
    public FechaAsignacion:any;    
    public FechaCierre:any;    
    public FechaEstimadaCierre:any;    
    public EstructuraId:number|null = null;    
    public Asunto='';  
    public EmpleadoIdSolicitante:number|null = null;    
    public ClienteId:number|null= null;    
    public Descripcion='';  
    public UsuarioRegistroId = 0;    
    public VisibleApp=false;   
    public VisibleWebS=false;   
    public VisibleWebA=false;   
    public Rejects = 0;    
    public Horas= 0;
    public Minutos= 0;
    public HorasEstimado:number|null  = null;    
    public MinutosEstimado:number|null  = null;    
    public EjecucionEmailDetalleId:number|null  = null;    
    public TicketClasificacionId:number|null  = null;
    public JobConfiguracionTicketId:number|null  = null;    
    public TicketPilaSeguimientoNivelId:number|null  = null;
    public Estructura:any;
    public Cliente:any;
    public EmpleadoSolicitante:any;
    public EmpleadoAsignado:any;
    public TicketPrioridad:ITicketPrioridad|null=null;
    public TicketEstatus:ITicketEstatus|null=null;
    public TicketClasificacion:ITicketClasificacion|null=null;
    public TicketInventario:Array<any>|null=null;
    public UsuarioRegistro='';  
    public TicketRetrasado=false;;
    public TotalEvidencias=0;
    public EmpresaId=0;
    public Empresa:any;    
    public IsRejected=false;    
    public TicketDias=0;
    public ResolveEstructura=false;
    constructor(data?: any)
    {
        Object.assign(this, data);
        this.unifica();
    }
    public unifica()
    {
        this.FechaRegistro =this.FechaRegistro? toDate(this.FechaRegistro):null;
        this.FechaAsignacion =this.FechaAsignacion? toDate(this.FechaAsignacion):null;
        this.FechaCierre =this.FechaCierre? toDate(this.FechaCierre):null;
        this.FechaEstimadaCierre =this.FechaEstimadaCierre?  toDate(this.FechaEstimadaCierre):null;        
    } 
}
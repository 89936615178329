
import * as CryptoJS from 'crypto-js';
export default class CService
{  
  private skBuffer: any;
  private sivBuffer: any;
  constructor(sk: string, iv: string)
  {    
    this.skBuffer = CryptoJS.enc.Utf8.parse(sk);
    this.sivBuffer = CryptoJS.enc.Utf8.parse(iv);
  }
  public Encrypt(value: string): string
  {
    if (!value)
    {
      return '';
    }
    const encrypted = CryptoJS.AES.encrypt(value, this.skBuffer,
    {
      keySize: 128 / 8,
      iv: this.sivBuffer,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }
  public Decrypt(textToDecrypt: string)
  {
    if (!textToDecrypt)
    {
      return null;
    }
    const decrypted = CryptoJS.AES.decrypt(textToDecrypt, this.skBuffer,
    {
      keySize: 128 / 8,
      iv: this.sivBuffer,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  public ToMD5(value: string)
  {
    return CryptoJS.MD5(value).toString();
  }
}
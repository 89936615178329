import { eTipoNotificacion } from "../GloblasEnum";

export interface INotificacionUI
{
    Tipo:number,
    Id: number,
    Status: string,
    StatusText: string,
    Message: string,
    Meta: any,
    Activo: boolean
}
export interface INotificacionErrorUI extends INotificacionUI
{
    Url:string,
    ResponseText:any,
    Stack: string,
    Error: any
}
export default class NotificacionUI implements INotificacionUI
{ 
    public Tipo = 0;
    public Id = 0;
    public Status = '';
    public StatusText = '';
    public Message = '';
    public Meta: any|null = null;
    public Activo = false;
    public InternalCode ='';
    public StatusCode ='';
}
export class NotificacionErrorUI extends NotificacionUI implements INotificacionErrorUI,INotificacionUI
{ 
    public Url = '';
    public ResponseText: any|null = null;
    public Stack = '';
    public Error: any|null = null;
    constructor(error?: any)
    {
      super();
      this.Tipo = eTipoNotificacion.Error;
      if(error)
      {
        this.BuildError(error);
      }
    }
    protected BuildError(error?: any)
    {
      let mapped = false;      
      if (error instanceof Error)
      {         
        if((error as any).response && (error as any).response.data)
        {
          this.Message = (error as any).response.data.Message;
          this.StatusCode = (error as any).response.data.StatusCode;
          this.InternalCode =(error as any).response.data.InternalCode;          
          this.Stack = error.stack? error.stack: '';            
          this.ResponseText = error.message; 
          this.Status = (error as any).status;
          this.StatusText = (error as any).statusText;
        }
        else
        {
          this.StatusText = error.name;
          this.Message = error.message;
          this.Stack = error.stack? error.stack: '';  
          this.Meta = error.stack? error.stack: '';  
        }
        mapped = true;
      }
      if (error && error.Message && !mapped)
      {
        this.Message = error.Message;
        mapped = true;
      }
      if (typeof error === 'string' )
      {
        this.Message = error;
        mapped = true;
      }
      if(error && !mapped)
      {       
        mapped = true;         
        if (error.response) 
        {          
          this.Url = error.response.config.url;
          this.Status = error.response.status;
          this.StatusText =error.response.statusText;
        } 
        if(error.request)
        {
          this.ResponseText = error.request.responseText;               
        }        
        this.Message = error.message;
        this.Error = error;
      }
      if(!mapped)
      {
        this.Message = 'Error';
        this.Error = error;
      }      
    }
}
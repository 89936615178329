import { JoinConcat } from "../GloblasEnum";

export interface TipoCampo
{
    TipoCampoId:number;
    TipoDato:number;
    Nombre:string;
    CampoId:string;
    IsMultiple:boolean;
}
export class DataExtension
{
    public Id = 0;    
    public Data:Array<TableExtension> = [];
    public Init(data: any)
    {
        this.Data = new Array<TableExtension>();
        if(data)
        {        
            for (const d of data)
            {
                if(d)
                {
                    const tc:TipoCampo = {
                        TipoCampoId:d.CatalogoCustomDetalle.TipoCampo.TipoCampoId,
                        TipoDato: d.CatalogoCustomDetalle.TipoCampo.TipoDato,
                        Nombre: d.CatalogoCustomDetalle.TipoCampo.Nombre,
                        CampoId: d.CatalogoCustomDetalle.TipoCampo.CampoId,
                        IsMultiple: d.CatalogoCustomDetalle.Multiple
                    };                
                    const te: TableExtension = new TableExtension();
                    te.Campo = d.CatalogoCustomDetalle.Nombre;
                    te.TableExtensionId = d.TableExtensionId;
                    te.CatalogoCustomId = d.CatalogoCustomId;
                    te.CatalogoCustomDetalleId = d.CatalogoCustomDetalleId;
                    te.EntityId = d.EntityId;
                    te.Data = d.Data;
                    te.Title = d.CatalogoCustomDetalle.Title.Titulo;
                    te.TipoCampo = tc;
                    te.Activo = d.Activo;                    
                    this.Data.push(te);
                }
            }
        }
    }    
}
export class TableExtension
{
    public Campo = "";
    public TableExtensionId = 0;
    public CatalogoCustomId = 0;
    public CatalogoCustomDetalleId = 0;
    public EntityId = 0;
    public Data = "";
    public OldData = "";
    public Title = "";
    public TipoCampo:TipoCampo|any = null;
    public Activo = false;
    get Value()
    {
        let val = null;
        switch(this.TipoCampo.TipoDato)
        {
            case 1:
                val = this.Data;
                break;
            case 2:
                val =this.TipoCampo.IsMultiple? this.Data : parseInt(this.Data,10);
                break;
            default:
                val = this.Data;
                break;
        }
        return val;
    }
}
export class ExtensionSchema
{    
    public CatalogoCustom:any;
    public Catalogos:any;
    public getCatalogoCustomId():number
    {
        if(this.CatalogoCustom.Detalle)
        {           
            return this.CatalogoCustom.Detalle[0].CatalogoCustomId;        
        }
        else
        {
            return 0;
        }
    }
    public getCustomDetail(nombre:string):any
    {
        if(this.CatalogoCustom && this.CatalogoCustom.Detalle)
        {
            const detail = this.CatalogoCustom.Detalle.find((x:any) => x.Nombre === nombre);
            return detail;        
        }
        else
        {
            return null;
        }
    }
    public getTitle(nombre:string):string
    {
        const detail = this.getCustomDetail(nombre);        
        if(detail)
        {
            return detail.Title? detail.Title.Titulo:'';
        }        
        else
        {
            return "";
        }
    }
    public GetCatalogo(id:string)
    {
      const cat = this.Catalogos.filter((x:any) => x.Tipo.Nombre === id);          
      return cat && cat[0] && cat[0].Entities ? cat[0].Entities : new Array<any>();            
    }
    protected getValueFromCatalogo(tipoCampo:TipoCampo, catalogo:string,propId: string, val:any):any
    {
        const data = this.GetCatalogo(catalogo);
        if(data)
        {
            if(tipoCampo.IsMultiple)
            {
                const datas = new String(val).split(JoinConcat);
                const values = [];                
                for (const d of datas) 
                {
                    const dataArray = data.find((x:any)=> x[propId] === parseInt(d,10));
                    if(dataArray)
                        values.push(dataArray);
                }
                return values;
            }
            else
            {
                const value = data.find((x:any)=> x[propId] === val);
                return value? {...value}:null;
            }

        }
    }
    public ExtensionToNativeObject(object:any): any
    {
        if(object.DataExtensionJS)
        {
            const ext:DataExtension = object.DataExtensionJS;
            if(ext)
            {
                for (const extension of ext.Data)
                {
                    if(extension)
                    {
                        let val = null;
                        const id = extension.Value;
                        switch(extension.TipoCampo.TipoDato)
                        {
                            case 1:
                                val = extension.Value;
                                break;
                            case 2:                                
                                val = this.getValueFromCatalogo(extension.TipoCampo,extension.TipoCampo.Nombre,extension.TipoCampo.CampoId,id);
                                break;
                            default:
                                val = extension.Value;
                                break;
                        }
                        object[extension.Campo] = val;
                    }
                }
            }
        }
        return object;
    }
    public NativeToTableExtension(object: any):any
    {
        if(object.DataExtensionJS)
        {
            const tableJs:DataExtension = object.DataExtensionJS;
            for (const extjs of tableJs.Data)
            {
                const table = object.DataExtension.Data.find((x:any) => x.CatalogoCustomId === extjs.CatalogoCustomId && 
                                                                   x.CatalogoCustomDetalleId === extjs.CatalogoCustomDetalleId);
                if(table)
                {
                    let val = null;
                    switch(extjs.TipoCampo.TipoDato)
                    {
                        case 1:
                            val = object[extjs.Campo]?object[extjs.Campo]:null;
                            break;
                        case 2:                                
                            val = object[extjs.Campo]?  object[extjs.Campo][extjs.TipoCampo.CampoId]:null;
                            break;
                        default:
                            val = null;
                            break;
                    }                    
                    table.Data = val;
                    table.MetaInfo = table.Borrar? false : true;
                }                                                                   
            }
            for (const tbl of object.DataExtension.Data)
            {
                if(tbl)
                {
                    if(tbl.TableExtensionId > 0 && !tbl.MetaInfo)
                    {
                        tbl.Borrar = true;                        
                    }
                }
            }
        }
        return object
    } 
}
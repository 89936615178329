import RouteService from "@/services/RouteService";
import TestService from "@/services/TestService";
import LoginService from "@/services/LoginService";
import { defineStore } from "pinia";
import CService from "@/services/CService";
import { getAlertifyService , getDataBaseService} from "@/services/ui/GlobalFunctions";
import IAlertifyService from "@/model/services/IAlertifyService";
import IDataBaseService from "@/model/services/IDataBaseService";
import { useMainStore } from "@/store/main-store";
import SynLocalDBService from "@/services/SynLocalDBService";
import NotificacionesUserAppService from "@/services/NotificacionesUserAppService";
import AppUIManagerService from "@/services/ui/AppUIManagerService";
import SignalRService from "@/services/SignalRService";
import { ChatService } from "@/services/ChatService";
import { MessageManagerService } from "@/services/MessageManagerService";
import RegistroService from "@/services/RegistroService";
import TicketService from "@/services/TicketService";
import { StorageService } from "@/services/StorageService";
import DocumentoEvidenciaService from "@/services/DocumentoEvidenciaService";
import Productos2Service from "@/services/Productos2Service";
import SecurityManagerService from "@/services/SecurityManagerService";
import AppSystemSettingsService from "@/services/AppSystemSettingsService";
import BarCodeService from "@/services/BarCodeService";
export const useServicesFactoryStore = defineStore('servicesfactorystore', 
{
  state: () =>
  {
    const mainStore = useMainStore();    
    const alertifyService:IAlertifyService = getAlertifyService();
    const cService = new CService(process.env.VUE_APP_SECRET_KEY? process.env.VUE_APP_SECRET_KEY:'',
                                  process.env.VUE_APP_SECRET? process.env.VUE_APP_SECRET:'');
    const databaseService:IDataBaseService = getDataBaseService(cService);       
    const routeService = new RouteService(); 
    const uiManager = new AppUIManagerService();       
    const param = {appconfig: mainStore.getAppConfig};
    const loginService = new LoginService(param,databaseService);
    const notiAppUsrService = new NotificacionesUserAppService(param);
    const testService = new TestService(param); 
    const synLocalDBService = new SynLocalDBService(param); 
    const chatService =  new ChatService(param);
    const messageManager = new MessageManagerService({chat:chatService});
    const signalRService = new SignalRService(messageManager,param);    
    const registroService = new RegistroService(param,databaseService);
    const ticketService = new TicketService(param,databaseService);
    const productos2Service = new Productos2Service(param);
    const documentoEvidenciaService = new DocumentoEvidenciaService(param);
    const storageService = new StorageService();
    const securityManagerService = new SecurityManagerService(param,uiManager);
    const appSystemSettings = new AppSystemSettingsService(param,databaseService);
    const barCodeService = new BarCodeService(uiManager,param);
    return {
      Services:
      {
        alertifyService,
        testService,
        loginService,
        routeService,
        cService,        
        databaseService,
        synLocalDBService,
        notiAppUsrService,
        uiManager,
        signalRService,
        chatService,
        registroService,
        ticketService,
        documentoEvidenciaService,
        storageService,
        productos2Service,
        securityManagerService,
        appSystemSettings,
        barCodeService        
      }
    }
  },
  getters: 
  {
    getTestService: (state) => state.Services.testService,    
    getAlertifyService: (state):IAlertifyService => state.Services.alertifyService,
    getLoginService: (state) => state.Services.loginService, 
    getRouteService: (state) => state.Services.routeService, 
    getCService:(state) => state.Services.cService,    
    getDBService:(state) => state.Services.databaseService,
    getSynLocalDBService:(state) => state.Services.synLocalDBService,
    getNotiAppUsrService:(state):NotificacionesUserAppService =>{return  state.Services.notiAppUsrService as NotificacionesUserAppService},
    getUIManager : (state):AppUIManagerService => state.Services.uiManager as AppUIManagerService,
    getSignalRService : (state) => state.Services.signalRService,
    getChatService : (state):ChatService => {return  state.Services.chatService as ChatService},
    getRegistroService: (state):RegistroService => {return  state.Services.registroService as RegistroService},
    getTicketService: (state):TicketService => {return  state.Services.ticketService as TicketService},
    getProductos2Service: (state):Productos2Service => {return  state.Services.productos2Service as Productos2Service},
    getDocumentoEvidenciaService: (state):DocumentoEvidenciaService => {return  state.Services.documentoEvidenciaService as DocumentoEvidenciaService},
    getStorageService: (state):StorageService => {return  state.Services.storageService as StorageService},
    getSecurityManagerService: (state):SecurityManagerService => {return  state.Services.securityManagerService as SecurityManagerService},
    getAppSystemSettings: (state):AppSystemSettingsService => {return  state.Services.appSystemSettings as AppSystemSettingsService},
    getBarCodeService: (state):BarCodeService => {return  state.Services.barCodeService as BarCodeService}
  },
  actions:
  {
    //getService<T>(type: T){}
  },
});




import { CustomGuardWeb} from '@/router/routesApp'

import { createRouterByPlatform} from '@/router/routesApp';
const platform = process.env.VUE_APP_PLATFORM?.toLowerCase();
const router: any = createRouterByPlatform(platform);

//https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach(async (to:any) => 
{   
  let response = null;




  response = await CustomGuardWeb(to);

  return response;  
});
export function getRouter(): any
{      
  return router;
}
export default router

import { ISesionApp } from "@/model/ISesionApp";
import BaseService from "./base/BaseService";
import moment from 'moment'
import { IServiceResponse } from "@/model/IServiceResponse";
import {eTipoNotificaciones} from '@/model/GloblasEnum';
import { isProxy, toRaw } from 'vue';


export interface INotficacionResult
{
  TotalPagesApp:number;
  TotalPagesWeb:number;
  Notificaciones:Array<any>
}
export default class NotificacionesUserAppService extends BaseService
{  
  constructor(param?: any)
  {
    super(param);    
  }
  public async AllNoficacionesUserAsync(sesion:ISesionApp,appHasNotis:boolean,webHasNotis:boolean,page: number):Promise<INotficacionResult>
  {    
    const Result:INotficacionResult = { TotalPagesApp:  0,TotalPagesWeb:0, Notificaciones:[]};    
    const AddToResult = (notis:Array<any>, tipo:number,empresa?:any)=>
    {
      if(notis)
      {
        notis.forEach((n:any)=> 
        {
          n.TipoNotificacion = tipo;
          if(empresa)
          {
            n.Empresa = empresa;
            n.EmpresaId = empresa.EmpresaId;
          }
          Result.Notificaciones.push(n);
          
        });
      }
    };
    let Response:IServiceResponse;
    if(appHasNotis)
    {
      Response = await this.Get(`IndexNotifiacionAppUsuario/NoficacionesUser/${page}/${sesion.User?.AppUsuarioId}`,null,null,null,true,undefined,null);      
      if(Response && Response.Response)
      {      
        Result.TotalPagesApp= parseInt(Response.OtherResponse, 10);      
        AddToResult(Response.Response,eTipoNotificaciones.NotifiacionAppUsuario);        
      }
    }
    if(sesion.SyncData.IsEmpleado && webHasNotis)
    {      
      Response = await this.Get('IndexNotificaciones/NoficacionesUser/' + page,null,null,null,true,undefined,null);  
      if(Response && Response.Response)
      {
        let empresa;
        const empresaAux = sesion.SyncData.getEmpresaById(sesion.getEmpresaId());
        if (isProxy(empresaAux)) empresa = toRaw(empresaAux);        
        Result.TotalPagesWeb = parseInt(Response.OtherResponse, 10);        
        AddToResult(Response.Response,eTipoNotificaciones.NotificacionUsuario,empresa);
        
      }
    }
    if(Result.Notificaciones)
    {
      Result.Notificaciones.forEach((n:any) => {n.FechaRegistro = moment(n.FechaRegistro).toDate();});
      Result.Notificaciones = Result.Notificaciones.sort((a: any, b: any) => {return b.FechaRegistro - a.FechaRegistro});      
    }
    return Result;
  }  
  public async NoficacionesUserVistaAsync(NotifiacionAppUsuarioId: number)
  {    
    await this.Get("IndexNotifiacionAppUsuario/NoficacionesUserVista/" + NotifiacionAppUsuarioId,null,null,null,true,undefined,null);  
  }
  public async NoficacionesWebVistaAsync(NotificacionUsuarioId: number)
  {    
    await this.Get(`IndexNotificaciones/NoficacionVista/${NotificacionUsuarioId}`,null,null,null,true,undefined,null);  
  }
  public async RemoveNotificacionAsync(NotifiacionAppUsuarioId: number)
  {
    await this.Get("IndexNotifiacionAppUsuario/RemoveNotificacion/" + NotifiacionAppUsuarioId);      
  }
  public async RemoveNotificacionWebAsync(NotificacionUsuarioId: number)
  {
    await this.Get(`IndexNotificaciones/RemoveNotificacion/${NotificacionUsuarioId}`);
  }
  public async NoficacionesTicketAsync(sesion:ISesionApp,EmpresaId: number, TicketId: number)
  {        
    if(!EmpresaId || !TicketId) return null;            
    const param = {
      AppUsuarioId:sesion.User && !sesion.SyncData.IsEmpleado? sesion.User.AppUsuarioId:0,
      UsuarioId:sesion.SyncData.getUsuarioId(),
      TablaOrigen:'Ticket',
      EmpresaId:EmpresaId,
      IdAsociado:TicketId,
    }    
    const response = await this.Post('IndexNotifiacionAppUsuario/NoficacionesUserByTable',param,null,null,()=>{this.showError()},true,false);    
    let notificaciones = response? response.Response:null;
    if(notificaciones)
    {
      for (const noti of notificaciones)
      {
        noti.FechaRegistro = moment(noti.FechaRegistro).toDate();
      }
      notificaciones = notificaciones.sort((a: any, b: any) => {return b.FechaRegistro - a.FechaRegistro});      
    }   
   return notificaciones; 
  } 
}

import {getRouter} from '@/router/index';
export default class RouteService
{
    public reload()
    {
      const router = getRouter();      
      router.go(0);
    }
    public go(routeName: string): void
    {
      const router = getRouter();      
      router.replace({ name: routeName });
      /*if(router.platform === 'web')
      {      
        router.replace({ name: routeName });
      }
      if(router.platform === 'app')
      {      
        //router.replace({ name: routeName });
        //console.log('/'+ routeName);
        router.replace({ name: routeName });
      }*/
    }  
    public goId(routeName: string,params:any): void
    {
      const router = getRouter();      
      router.replace({ name: routeName,params });
      /*if(router.platform === 'web')
      {      
        router.replace({ name: routeName });
      }
      if(router.platform === 'app')
      {      
        //router.replace({ name: routeName });
        //console.log('/'+ routeName);
        router.replace({ name: routeName });
      }*/
    }  
    public push(routeName: string): void
    {
      const router = getRouter();        
      router.push({ name: routeName})         
      /*if(router.platform === 'web')
      {      
        router.replace({ name: routeName });
      }
      if(router.platform === 'app')
      {      
        //router.replace({ name: routeName });
        //console.log('/'+ routeName);
        router.replace({ name: routeName });
      }*/
    }  
}
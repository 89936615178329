export class Where
{  
  public NombreCampo:string|null = null;
  public NombreParametro:string|null = null;
  public Valor: any;
  public TypeDB:string|null = null;
  public dbType:string|null = null;
  public Operador:string|null = null;
  public Condicion:string|null = null;
  public Type:string|null = null;
  public ToWhere(config: any, data: any): Array<Where>
  {    
    if (!data)
    {
      return [];
    }
    const d = [];
    for (const p in config)
    {
      if (p)
      {
        const val = data[p];
        if (val != null && val !== undefined && val !== '')
        {
          const w = new Where();
          w.NombreCampo = p;
          w.NombreParametro = p;
          w.Valor = val;
          w.Condicion = config[p];
          if (w.Condicion)
          {
            d.push(w);
          }
        }        
      }
    }    
    return d;
  }
}

/* eslint-disable @typescript-eslint/no-this-alias */
import * as signalR from "@microsoft/signalr";
import BaseService from "./base/BaseService";
import { ISesionApp } from "@/model/ISesionApp";
import { ISesionUsuarios, SesionUsuarios } from "@/model/ISesionUsuarios";
import { BehaviorSubject } from "rxjs";
import { eSignalRInitAttemp, eSignalRMaxAttemps } from "@/model/GloblasEnum";
import { MessageManagerService } from "@/services/MessageManagerService";
//import { isPlatform, getPlatforms } from '@ionic/vue';
import { getPlatform } from "./ui/GlobalFunctions";
export default class SignalRService extends BaseService
{ 
    public SesionHub: BehaviorSubject<ISesionUsuarios|null>;
    protected urlSingalR: string;
    protected SRAttempsReconect:number;
    protected SesionUsuarios:ISesionUsuarios|null = null;
    protected MessageManager:MessageManagerService;         
    constructor(messageManager: MessageManagerService,param?: any)
    {
      super(param);                        
      this.urlSingalR = this.appConfig.urlSignalR;
      this.SesionHub = new BehaviorSubject<ISesionUsuarios|null>(null);
      this.SRAttempsReconect= eSignalRInitAttemp;
      this.MessageManager = messageManager;
    }
    protected getPlatformName():string
    {
        /*const defaultName = 'app';
        let pf = '';
        pf = isPlatform('ios')? 'ios': defaultName;
        pf = isPlatform('android')? 'android': defaultName;
        pf = isPlatform('desktop')? 'desktop': defaultName;
        pf = isPlatform('mobileweb')? 'mobileweb': defaultName;
        if(pf === 'app')
            pf = 'web';
        return pf;*/
        /*const platforms = getPlatforms();
        console.log(platforms);
        if(platforms && platforms.length > 0)
            return platforms[0];
        else 
            return 'app';*/
        return getPlatform();
    }
    protected async createSession():Promise<ISesionUsuarios|null>
    {
        const ptfrm = this.getPlatformName();
        const respAux = await this.Get("IndexUserProfile/CreateSession/"  + ptfrm,null,null,null,true,false);        
        if(respAux && respAux.Response)
        {
            return new SesionUsuarios(respAux.Response);
        }
        return null;
    }      
    public async startsesion():Promise<void>
    {        
        const sesionapp :ISesionApp|null = await this.baseGetSesion();
        //sesionapp.SignalRActive = true;
        if (!sesionapp || !sesionapp.SyncData || !sesionapp.SyncData.SignalRActive) {return;}
        this.SesionUsuarios = await this.createSession();
        if(!this.SesionUsuarios) return;
        const tokenAPI:string = sesionapp.User?.TokenApi ? sesionapp.User?.TokenApi:'';
        const options: signalR.IHttpConnectionOptions = 
        {
            accessTokenFactory: () => {return tokenAPI;}
        };
        const gestor = this;        
        const connection = new signalR.HubConnectionBuilder()
        .withUrl(this.urlSingalR + '?SesionUsuarioId=' + this.SesionUsuarios.TokenSession, options)
        .configureLogging(signalR.LogLevel.Information)
        .build();
        connection.on('notification', (tipo: any, message: any, activity: any, parametros: any) =>
        {
            const t = parseInt(tipo, 10);  
            /*console.log(activity);          
            console.log(message); 
            console.log(parametros);   */
            (gestor as any)[activity](t, message, parametros);
            //let method: keyof SignalRService;
            //method = activity;
           // const t = parseInt(tipo, 10);
            //const functionDyn = activity ? activity : activity;
            //if(gestor && activity)
            //gestor[activity](t, message,activity, parametros);
            //gestor.ProcesaMensaje(t, message,activity, parametros);
        });
        connection.onclose(() =>
        {            
            this.SesionHub.next(null);            
            this.AutoReconect();
        });
        await connection.start();//.then().catch((console.error));        
    }    
    protected AutoReconect()
    {
        setTimeout(async () =>
        {
            await this.Reconect();
        }, 10000);
    }       
    protected async Reconect()
    {
        //this.loginwebservice.CheckSession();
        if (this.SRAttempsReconect <= eSignalRMaxAttemps)
        {
            await this.startsesion();
            this.SRAttempsReconect++;
        }
    }
    protected RegisterId(tipo: number, idSession: string): void
    {            
      if(this.SesionUsuarios)
      {
        this.SesionUsuarios.TokenHub = idSession;
        this.SesionHub.next(this.SesionUsuarios);      
        console.log('signal iniciado from vue-service ' + idSession );      
      }
      if (tipo)
      {        
        this.alertify.Error(idSession);
        this.AutoReconect();
      }
      else
      {
        this.SRAttempsReconect = eSignalRInitAttemp;
      }
    }
    /*Metodos dinamicos para signalR */
    protected RegisterIdCore( tipo: number, message: string): void
    {
        this.RegisterId(tipo, message);
    }
    public ChatMessageRecived(tipo: number, message: string, parametros: any)
    {        
        this.MessageManager.ChatMessageRecived(parametros);        
    }
    protected StatusChangeFromHub(tipo: number, message: string, parametros: any)
    {
        this.MessageManager.StatusChangeFromHub(parametros);       
    } 
}
import { ServiceResponse } from './../model/IServiceResponse';
import { IServiceResponse } from '@/model/IServiceResponse';
import { ITicketPrioridad, Ticket, ITicketEstatus, ITicketClasificacion, IDocumentoEvidencia, ITicketInventario } from './../model/tickets/ITicket';
import BaseService from "./base/BaseService";
import IDataBaseService from "@/model/services/IDataBaseService";
import { eModoTicket, eTicketStatus, eTipoUsuarioTicket } from '@/model/GloblasEnum';

export default class TicketService extends BaseService
{      
  protected DBServie :IDataBaseService | undefined;
  //protected empresaId = 0;
  protected estatusTicket:ITicketEstatus[];
  protected prioridadTicket:ITicketPrioridad[];
  protected clasificacionTicket: ITicketClasificacion[];
  public get estatus():ITicketEstatus[] {return this.estatusTicket;}   
  public get prioridad():ITicketPrioridad[] {return this.prioridadTicket;}   
  public get clasificacion():ITicketClasificacion[] {return this.clasificacionTicket;}   
  //public get empresa():number {return this.empresaId;}  
  constructor(param?: any,dbService?: IDataBaseService)
  {
    super(param); 
    this.DBServie = dbService;
    this.estatusTicket = new Array<ITicketEstatus>();
    this.prioridadTicket = new Array<ITicketPrioridad>();
    this.clasificacionTicket = new Array<ITicketClasificacion>();
  }  
  public async MisTicketsSolicitadosAsync(EmpresaId: number, AppUsuarioId: number, Tipo:eTipoUsuarioTicket):Promise<Ticket[]|null>
  {
      if (EmpresaId <= 0 || AppUsuarioId <= 0) return null;
      const result = new Array<Ticket>();      
      this.SetEmpresaId = EmpresaId;
      let serviceResponse:IServiceResponse = new ServiceResponse();                                             
      if(Tipo == eTipoUsuarioTicket.AppUsuario)
      {
        serviceResponse = await this.Get("IndexTicket/MisTicketsSolicitadosAPP/" + AppUsuarioId,null,null,null,true,undefined,null);
      }
      else
      {
        serviceResponse = await this.Get("IndexTicket/AllTicketsEmpleadoApp/" + AppUsuarioId,null,null,null,true,undefined,null);
      }            
      if(serviceResponse)
      {
        for (const ticket of serviceResponse.Response)
        {
          result.push(new Ticket(ticket));  
        }
      }      
      return result;
  }
  public  GetModoTicket(ticket:Ticket, empleadoId:number):eModoTicket
  {            
      if (ticket == null) return eModoTicket.Solicitado;      
      if (empleadoId && ticket.EmpleadoIdSolicitante == empleadoId)
          return eModoTicket.Solicitado;
      if (empleadoId && ticket.EmpleadoId == empleadoId)
          return eModoTicket.Asignado;
      return eModoTicket.Solicitado;
  }  
  public async Inicializa(EmpresaId:number,force?:boolean):Promise<void>
  {
    //if(this.empresaId !== EmpresaId || this.empresaId == 0 || force)
    if(EmpresaId || force)
    {
      this.estatusTicket = await this.TicketEstatusAsync(EmpresaId);
      this.prioridadTicket = await this.TicketPrioridadAsync(EmpresaId);
      this.clasificacionTicket = await this.TicketClasificacionAsync(EmpresaId);      
      //this.empresaId = EmpresaId;
    }
    else
    {
      this.estatusTicket = [];
      this.prioridadTicket = [];
      this.clasificacionTicket = [];      
    }
  }
  protected async  TicketPrioridadAsync(EmpresaId:number):Promise<ITicketPrioridad[]>
  {
    this.SetEmpresaId = EmpresaId;
    let serviceResponse:IServiceResponse = new ServiceResponse();  
    serviceResponse = await this.Get("IndexTicket/GetActiveTicketPrioridadApp",null,null,()=>{this.showError()},true,false);
    return serviceResponse && serviceResponse.Response? serviceResponse.Response:null;
  }
  public async TicketEstatusAsync(EmpresaId:number):Promise<ITicketEstatus[]>
  {
    this.SetEmpresaId = EmpresaId;
    let serviceResponse:IServiceResponse = new ServiceResponse();  
    serviceResponse = await this.Get("IndexTicket/GetActiveTicketEstatus",null,null,()=>{this.showError()},true,false);
    return serviceResponse && serviceResponse.Response? serviceResponse.Response:null;        
  }
  public async TicketClasificacionAsync(EmpresaId:number):Promise<ITicketClasificacion[]>
  {
    this.SetEmpresaId = EmpresaId;
    let serviceResponse:IServiceResponse = new ServiceResponse();  
    serviceResponse = await this.Get("IndexTicketCustom/GetActiveClasificacion",null,null,()=>{this.showError()},true,false);
    return serviceResponse && serviceResponse.Response? serviceResponse.Response:null;
  }
  public async GetTicketById(ticketId: number,EmpresaId:number):Promise<Ticket>
  {
    //if (!ticketId || !this.empresaId) return new Ticket();     
    if (!ticketId || !EmpresaId) return new Ticket();     
    this.SetEmpresaId = EmpresaId;
    let serviceResponse:IServiceResponse = new ServiceResponse();  
    serviceResponse = await this.Get("IndexTicket/GetById/" + ticketId,null,null,()=>{this.showError()},true,false);    
    return serviceResponse && serviceResponse.Response? serviceResponse.Response:new Ticket();
  }
  public async ShowConfirmTicket(title: string, body: string, val: string,buttons:string[],fnOnClose:any): Promise<any>
  {
    return this.alertify.Prompt(title,body,val,buttons,(d:any)=>
    {
      if (fnOnClose)
        fnOnClose(d);
    },()=> {return 0 ;})
  }
  public async TicketEvidenciaAsync(ticket:Ticket):Promise<IDocumentoEvidencia[]>
  {
    if (!ticket || !ticket.EmpresaId) return [];  
    this.SetEmpresaId = ticket.EmpresaId;
    let serviceResponse:IServiceResponse = new ServiceResponse(); 
    const url = "IndexDocumentoEvidencia/TicketEvidencia/" + ticket.TicketId;
    serviceResponse = await this.Get(url,null,null,()=>{this.showError()},true,false); 
    return serviceResponse && serviceResponse.Response? serviceResponse.Response:[];
  }
  public async AddEvidenceTicketApp(ticket:Ticket, evidencias: IDocumentoEvidencia[]):Promise<Ticket|null>
  {    
    if (!ticket || !ticket.EmpresaId || !evidencias || evidencias.length < 0) return null; 
    for (const evidencia of evidencias) 
    {
      //evidencias.Content -> este se envia a ftp file server
      evidencia.Content64 = '';        
    }
    this.SetEmpresaId = ticket.EmpresaId;
    let serviceResponse:IServiceResponse = new ServiceResponse();               
    serviceResponse = await this.Post("IndexTicket/AddEvidenceTicketApp/" + ticket.TicketId,evidencias);                 
    return serviceResponse && serviceResponse.Response? serviceResponse.Response:null;
  }
  public async FindElement(EmpresaId:number,codigo: string):Promise<any[]>
  {
    if (!EmpresaId || !codigo ) return [];
    const find = {
      Codigo : codigo,
      Nombre : codigo            
    }
    this.SetEmpresaId = EmpresaId;
    let serviceResponse:IServiceResponse = new ServiceResponse();       
    serviceResponse = await this.Post("IndexTicketCustom/FindElement", find);      
    return serviceResponse && serviceResponse.Response? serviceResponse.Response:[];
  }
  public ToInventory(result: any, selection:any):ITicketInventario|null
  {
      let inventario:any = {};
      if (!result || result.length <= 0) return null;      
      if (!selection || selection.id <= 0) return null;      
      const existeP = result.filter((x:any) => x.IsProducto && x.ProductoId === selection.id);
      const existeS = result.filter((x:any) => !x.IsProducto && x.ServicioId === selection.id);    
      inventario.TicketInventarioId = 0;
        inventario.Cantidad = 1;
      inventario.Activo = true;
      inventario.Borrar = false;      
      if (existeS && existeS.length > 0) 
      {
        inventario.IsProducto = false;                
        inventario.ServicioId = existeS[0].ServicioId;                
        inventario.Servicio = existeS[0].Servicio;
      }     
      if(existeP && existeP.length > 0)
      {
          inventario.IsProducto = true;
          inventario.ProductoId = existeP[0].ProductoId;                
          inventario.Producto = existeP[0].Producto;                
      }      
      if (!existeP && !existeS) inventario = null;
      return inventario;
  }
  /**
   * Se modifica un ticket asignado al empleado.
   * @param original Ticket a modificar.
   * @param changes cambios tomados del from.
   * @returns repuesta del ws.
   */
  public ToTicketAsignado(original:Ticket,changes:any):Ticket
  {
    if(original && changes)
    {
      original.Nota = changes.nota;
      original.TicketEstatus = changes.estatus;       
      original.TicketEstatusId = changes.estatus? changes.estatus.TicketEstatusId:null;
      original.TicketClasificacion = changes.clasificacion; 
      original.TicketClasificacionId = changes.clasificacion? changes.clasificacion.TicketClasificacionId:null;
      original.FechaEstimadaCierre = changes.fcierre;
      original.Horas = changes.horas;
      original.Minutos = changes.minutos;
      original.NotaInterna= changes.notaInterna;      
    }
    return original;
  }
  /**
   * Se modifica un ticket solicitado por el empleado.
   * @param original Ticket a modificar.
   * @param changes cambios tomados del from.
   * @returns repuesta del ws.
   */
  public ToTicketSolicitado(original:Ticket,changes:any):Ticket
  {
    if(original && changes)
    {
      original.Asunto = changes.asunto;
      original.TicketPrioridad = changes.prioridad;       
      original.TicketPrioridadId = changes.prioridad? changes.prioridad.TicketPrioridadId:null;
      original.Descripcion = changes.descripcion; 
      original.Nota = changes.nota;      
    }
    return original;
  }
  /**
   *  Se modifica un ticket solicitado por el cliente o proveedor.
   * @param original Ticket a modificar.
   * @param changes cambios tomados del from.
   * @returns repuesta del ws.
   */
  public ToTicketSolicitadoNoEmpleado(original:Ticket,changes:any,idIdentificador:number):Ticket
  {
    if(original && changes && idIdentificador)
    {
      original.Asunto = changes.asunto;
      original.TicketPrioridad = changes.prioridad;       
      original.TicketPrioridadId = changes.prioridad? changes.prioridad.TicketPrioridadId:null;
      original.TicketEstatus = changes.estatus;       
      original.TicketEstatusId = changes.estatus? changes.estatus.TicketEstatusId:null;
      original.Descripcion = changes.descripcion; 
      original.Nota = changes.nota;  
      original.ClienteId = idIdentificador; 
      original.EmpresaId = changes.EmpresaId;   
    }
    return original;
  }
  public GetTime(time:number):number[]
  {
      if (!time) return [0, 0];
      else
      {          
        const horas = Math.trunc(time / 60);
        const minutos = time % 60 ;
        return [horas, minutos];
      }
  }
  public async AsignTicketCloseApp(ticket:Ticket):Promise<Ticket|null>
  { 
    if (ticket == null || !ticket.EmpresaId) return null;
    let serviceResponse:IServiceResponse = new ServiceResponse(); 
    this.SetEmpresaId = ticket.EmpresaId;
    serviceResponse = await this.Post("IndexTicket/AsignTicketCloseApp",ticket,null,null,()=>{this.showError()},true,false);
    return serviceResponse && serviceResponse.Response? serviceResponse.Response:null;
  }
  public async SaveAsync(ticket:Ticket,tipo:eTipoUsuarioTicket,empleadoId?:number|null):Promise<Ticket|null>
  {
    if (ticket == null || !ticket.EmpresaId) return null;
    let serviceResponse:IServiceResponse = new ServiceResponse(); 
    this.SetEmpresaId = ticket.EmpresaId;
    if (tipo === eTipoUsuarioTicket.AppUsuario)
    {
      serviceResponse = await this.Post("IndexTicket/SaveAPP",ticket,null,null,()=>{this.showError()},true,false);
    }
    else
    {
        if (ticket.TicketId <= 0)
        {
            ticket.EmpleadoIdSolicitante = empleadoId? empleadoId:null;
            ticket.ClienteId = null;
            ticket.Interno = true;
        }
        serviceResponse = await this.Post("IndexTicket/SaveERPAPP",ticket,null,null,()=>{this.showError()},true,false);
    }
    return serviceResponse && serviceResponse.Response? serviceResponse.Response:null;    
  }
  public async ResolveTicketAppAsync(ticket: Ticket, Tipo:eTipoUsuarioTicket, isAproved: boolean):Promise<Ticket|null>
  {
    if (ticket == null || !ticket.EmpresaId) return null;
    let serviceResponse:IServiceResponse = new ServiceResponse(); 
    this.SetEmpresaId = ticket.EmpresaId;
    let url = '';
    if (Tipo == eTipoUsuarioTicket.AppUsuario)
    {
      url = (isAproved) ? "IndexTicket/ResolveTicketApp" : "IndexTicket/NoResolveTicketApp";
      serviceResponse = await this.Get(url + "/" + ticket.TicketId,null,null,()=>{this.showError()},true,false);
    }
    else
    {
      url = (isAproved) ? "IndexTicket/ResolveTicketEmpleadoApp" : "IndexTicket/NoResolveTicketEmpleadoApp";
      serviceResponse = await await this.Get(url + "/" + ticket.TicketId,null,null,()=>{this.showError()},true,false);
    }
    return serviceResponse && serviceResponse.Response? serviceResponse.Response:null;  
  }
  public async TicketOffAPPAsync(ticket: Ticket, Tipo: eTipoUsuarioTicket):Promise<Ticket|null> 
  {
    if (ticket == null || !ticket.EmpresaId) return null;
    let serviceResponse:IServiceResponse = new ServiceResponse(); 
    this.SetEmpresaId = ticket.EmpresaId;
    if(Tipo === eTipoUsuarioTicket.AppUsuario)
      serviceResponse = await this.Get("IndexTicket/TicketOffAPP/" + ticket.TicketId,null,null,()=>{this.showError()},true,false);
    else
      serviceResponse = await this.Get("IndexTicket/TicketEmpleadoOffAPP/" + ticket.TicketId,null,null,()=>{this.showError()},true,false);
    return serviceResponse && serviceResponse.Response? serviceResponse.Response:null;  
  } 
  public InicializaTicket(ticket: Ticket,isEmpleado:boolean, empleadoId:number|null):Ticket
  {
    const abierto = this.getEstatusOpen();
    ticket.TicketEstatus = abierto? abierto:null;
    ticket.TicketEstatusId = abierto? abierto.TicketEstatusId:0;
    ticket.FechaRegistro = new Date();
    if(isEmpleado)
    {
      ticket.EmpleadoIdSolicitante = empleadoId;
    }
    return ticket;
  }
  public getEstatusOpen():ITicketEstatus|undefined|null
  {
    const abierto = this.estatus? this.estatus.find((x:ITicketEstatus) => x.TicketEstatusId === eTicketStatus.Abierto):null;
    return abierto;
  }
}
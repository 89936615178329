export interface IAppUsuarios
{
    AppUsuarioId:number
    Nombre:string
    Apellidos:string
    Correo:string
    EmailConfirmed:boolean
    Clave:string
    Bloqueado:boolean
    Activo:boolean
    MotivoBloqueo:string
    Telefono:string
    FechaRegistro:Date|null
    FechaUltimoAcceso:Date|null
    EmailNotificaciones:boolean
    TokenApi:string
    /// <summary>
    /// Hace referencia a la tabla [ServicioCliente].[TokenId]
    /// ej 92c80291-920c-4130-ab4b-cbcfd00ee306 para hacer peticiones al servicio rest [CENTRAL IA]
    /// </summary>
    TokenVz:string
    FullName():string
}
export class AppUsuarios implements IAppUsuarios
{
    constructor(data?: any)
    {
        Object.assign(this, data);        
    }
    public AppUsuarioId = 0;
    public Nombre = '';
    public Apellidos= '';
    public Correo = '';
    public EmailConfirmed = false;
    public Clave = '';
    public Bloqueado = false;
    public Activo = false;
    public MotivoBloqueo = '';
    public Telefono = '';
    public FechaRegistro = null;
    public FechaUltimoAcceso= null;
    public EmailNotificaciones = false;
    public TokenApi = ''
    public TokenVz = ''
    public FullName():string
    {       
        return this.Nombre + " " + (this.Apellidos? this.Apellidos:'');
    }    
}
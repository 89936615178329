import schemaVirtualBlix from '@/assets/data-base-sqllite/virtualblixdb.json'
import IContextDBService from '@/model/services/db/IContextDBService';
import IQuery from '@/model/services/db/IQuery';
export default class ContextDBService implements IContextDBService
{       
  private schema:any;
  constructor()
  {    
    this.schema = schemaVirtualBlix;
  }  
  protected getTable(table:string)
  {
    const t = this.schema.tables.find((x: any) => x.name.toLowerCase() === table.toLowerCase());
    return t;
  }
  protected buildQueryResponse(sql: string, isNew?: boolean, values?:any):IQuery
  {
    return {sql,isNew: isNew? isNew:false,values};
  }
  public deleteString(table:string):IQuery
  {    
    return this.buildQueryResponse(' delete from ' + table);        
  }  
  public updateString(table:string,item: any,id: number):IQuery
  {
    const t = this.getTable(table);
    const values = [];    
    let sql1 = ' update ' + table + ' set ' ;    
    let c=0;
    for (const column of t.schema)
    {
      sql1 += column.column + ' = ?' + (c <(t.schema.length-1)? " , ":"");
      values.push(item[column.column]);
      c++;
    }
    sql1 +=" where id = ?";        
    values.push(id);
    return this.buildQueryResponse(sql1,false,values);
  }  
  public insertString(table:string,item: any)
  {
    const t = this.getTable(table);
    const values = [];
    let sql1 = 'insert into ' + table + ' ( ' ;
    let sql2 = ' values ( ' ;
    let c=0;
    for (const column of t.schema)
    {
      sql1 += column.column + (c <(t.schema.length-1)? " , ":"");
      sql2 += '?' + (c <(t.schema.length-1)? " , ":"");
      values.push(item[column.column]);
      c++;
    }
    sql1 +=")";
    sql2 +=")";
    sql1 = sql1 + sql2;
    return this.buildQueryResponse(sql1,true,values);
  }  
  public selectString(table:string,filter?:string,value?:any,condicion?:string):IQuery
  {
    const t = this.getTable(table);     
    let sql1 = '' ;    
    let c=0;
    for (const column of t.schema)
    {
      sql1 += column.column + (c <(t.schema.length-1)? " , ":"");      
      c++;
    }
    sql1 =" select " + sql1 + " from " + table; 
    if(filter && value)
    {
      if(!condicion)
      {
        condicion = ' = ';
      }
      sql1 += " where " + filter + ' ' + condicion + '?';
      console.log(sql1);
      console.log(value);
      return this.buildQueryResponse(sql1,false,value);
    }   
    else
    {
      return this.buildQueryResponse(sql1,false,null);
    }
  }
  public saveString(name:string,entity:any):IQuery
  {
    if(entity.Id)
      return this.updateString(name,entity,entity.Id);
    else
      return this.insertString(name,entity);        
  }  
}
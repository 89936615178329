import { IServiceResponse, ServiceResponse } from "@/model/IServiceResponse";
import BaseService from "./base/BaseService";
import { DataExtension, ExtensionSchema } from "@/model/productsCustom/DataExtension";
import { toDate } from "./ui/GlobalFunctions";
import { Where } from "@/model/Where";
import { eTipoFiltros } from "@/model/GloblasEnum";
export interface ProductsResult
{
  CurrentPage:number;
  TotalItems:number;
  Result:Array<any>;    
}
export default class Productos2Service extends BaseService
{          
  constructor(param?: any)
  {
    super(param);    
  }  
  public async GetSchemaProductos(): Promise<ExtensionSchema>
  {   
    const Schema = new ExtensionSchema();         
    const response = await this.Get('IndexInventarioCustom/ProductosSchema',null,null,null,true,false);
    if(response && response.Response)
    {      
      Schema.CatalogoCustom =  response.Response.CatalogoCustom;        
      Schema.Catalogos =  response.Response.Catalogos;                      
    }     
    return Schema;
  }
  public async SaveProducto(producto: any):Promise<any>
  {
    let Producto:any = null;    
    let serviceResponse:IServiceResponse = new ServiceResponse();                                             
    serviceResponse = await this.Post('IndexProductos/SaveProducto', producto);
    if(serviceResponse && serviceResponse.Response)
    {
      Producto = serviceResponse.Response;
    }
    return Producto;
  }
  public async GetProductosListAsync(Schema:ExtensionSchema|undefined,filtros?:any):Promise<ProductsResult>
  {
    const products:ProductsResult|any = new Object(); 
    //if (EmpresaId <= 0) return products;    
    const result = new Array<any>();      
    //this.SetEmpresaId = EmpresaId;
    let serviceResponse:IServiceResponse = new ServiceResponse();                                             
    serviceResponse = await this.Post("IndexInventarioCustom/ProductosList",filtros,null,null,null,true,undefined,null);
    if (serviceResponse && serviceResponse.Response && serviceResponse.Response.length > 0)
    {        
      serviceResponse.Response.forEach((e: any) =>
      {          
        result.push(this.buildDataExtension(e,e.DataExtension,Schema));
      });
      products.TotalItems = parseInt(serviceResponse.OtherResponse, 10);
      products.Result = result;      
    }
    return products;
  }  
  public async GetProductosExcel(filtros?: any):Promise<IServiceResponse>
  {        
    if(!filtros) filtros = [];
    //this.SetEmpresaId = EmpresaId;
    let serviceResponse:IServiceResponse = new ServiceResponse();                                             
    serviceResponse = await this.Post('IndexInventarioCustom/ProductosListExcel', filtros,null,null,null,true,undefined,'arraybuffer');
    return serviceResponse;
  }
  public async Getpdf(ProductoId: number):Promise<IServiceResponse>
  {    
    let serviceResponse:IServiceResponse = new ServiceResponse();                                             
    serviceResponse = await this.Get('IndexInventarioCustom/ProductoPDF/' + ProductoId,null,null,null,true,undefined,'arraybuffer' );
    return serviceResponse;
  }
  public async Getqr(Producto: any):Promise<IServiceResponse>
  {
    const p:any ={
      ProductoId:Producto.ProductoId,
      Codigo:Producto.Codigo, 
      Nombre:Producto.Nombre, 
      NoSerie:Producto.NoSerie, 
      Marca:Producto.Marca, 
      Modelo:Producto.Modelo
    };
    //this.SetEmpresaId = EmpresaId;
    let serviceResponse:IServiceResponse = new ServiceResponse(); 
    serviceResponse = await this.Post('IndexInventarioCustom/ProductoQR',p,null,null,null,true,undefined,'arraybuffer');
    return serviceResponse;
  }
  public async AsignacionEquipo(producto: any,asignacion:any):Promise<IServiceResponse>
  {    
    const a = {
      Producto:{ProductoId:producto.ProductoId},
      Asignacion:{EmpleadoId:asignacion.asignado.EmpleadoId},      
      Caracteristicas:asignacion.caracteristicas,      
      Entregado:{EmpleadoId:asignacion.entrega.EmpleadoId}
    }
    let serviceResponse:IServiceResponse = new ServiceResponse();     
    serviceResponse = await this.Post('IndexInventarioCustom/AsignacionEquipo',a,null,null,null,true,undefined,'arraybuffer');
    return serviceResponse;
  }
  public async BajaEquipo(producto: any,asignacion:any):Promise<IServiceResponse>
  {    
    const a = {
      Producto:{ProductoId:producto.ProductoId},      
      Asignacion:{EmpleadoId:asignacion.asignacion2.EmpleadoId},     
      Supervisado:{EmpleadoId:asignacion.supervisado.EmpleadoId},     
      Verificado:{EmpleadoId:asignacion.verificado.EmpleadoId}, 
      Ubicacion: asignacion.ubicacion,      
      Departamento: asignacion.departamento,
      MotivoBaja: asignacion.motivoBaja,
      Observaciones: asignacion.observaciones,
      Caracteristicas:asignacion.caracteristicas2,
      Folio:asignacion.folio
    }
    let serviceResponse:IServiceResponse = new ServiceResponse();     
    serviceResponse = await this.Post('IndexInventarioCustom/BajaEquipo',a,null,null,null,true,undefined,'arraybuffer');
    return serviceResponse;
  }
  public async GetProductoByCode(Schema:ExtensionSchema|undefined,code: string):Promise<IServiceResponse>
  {            
    let Producto:any;    
    let serviceResponse:IServiceResponse = new ServiceResponse();                                             
    serviceResponse = await this.Get('IndexProductos/GetByCode/' + code,null,null,null,true,undefined,null);
    if(serviceResponse && serviceResponse.Response)
    {
      Producto = serviceResponse.Response;
      const Extension = await this.ProductExtension(Producto);
      Producto = this.buildDataExtension(Producto,Extension.DataExtension,Schema);
    }    
    return Producto;
  }
  public async GaleriaProducto(ProductoId: number):Promise<IServiceResponse>
  {
    let serviceResponse:IServiceResponse = new ServiceResponse();                                             
    serviceResponse = await this.Get('IndexProductos/GaleriaProducto/' + ProductoId,null,null,null,true,undefined,null);
    return serviceResponse;
  }
  
  public async TableExtensionFile(id: number):Promise<IServiceResponse>
  {        
    let serviceResponse:IServiceResponse = new ServiceResponse();                                             
    serviceResponse = await this.Get(`IndexInventarioCustom/TableExtensionFile/${id}`,null,null,null,true,undefined,null);
    return serviceResponse;
  }
  protected async ProductExtension(producto: any):Promise<any>
  {        
    let serviceResponse:IServiceResponse = new ServiceResponse();     
    serviceResponse = await this.Get(`IndexInventarioCustom/ProductExtension/${producto.ProductoId}`,null,null,null,true,undefined,null);
    if(serviceResponse && serviceResponse.Response)
    {      
      return serviceResponse.Response;      
    }    
    return null;
  } 
  public buildDataExtension(producto: any,Extension:any, schema: ExtensionSchema|undefined):any
  {
    if(producto && Extension && Extension.Data)
    {
      const auxD = new DataExtension();
      auxD.Id = producto.ProductoId;      
      auxD.Init(Extension.Data);
      producto.DataExtension = Extension;
      producto.DataExtensionJS = auxD;      
      producto = schema? schema.ExtensionToNativeObject(producto):null;      
      producto.FechaCompra = producto.FechaCompra? toDate(producto.FechaCompra):null;
      producto.FechaRegistro =producto.FechaRegistro? toDate(producto.FechaRegistro):null;
    }
    return producto;
  }
  public getCustomIdWhere(id:number,campo:string):Where
  {
    const w = new Where();
    w.NombreCampo = campo;        
    w.Valor= id;
    w.Condicion= "=";
    w.Type = eTipoFiltros.custom;
    return w;
  }
  public getPairWhere(value:any,campo:string,condicion:string, detail:any):Where[]
  {    
    const w1 = new Where();
    w1.NombreCampo = "CatalogoCustomDetalleId";        
    w1.Valor= detail.CatalogoCustomDetalleId;
    w1.Condicion= "=";
    w1.Type= eTipoFiltros.custom;    

    const w2 = new Where();
    w2.NombreCampo = campo;        
    w2.Valor= value;
    w2.Condicion= condicion;
    w2.Type= eTipoFiltros.custom;
    return [w1,w2];    
  }
  public toNativeObject(object:any ,formData:any):any
  {
    object.Activo = formData.activo;
    object.Codigo = formData.codigo;
    object.Modelo = formData.modelo;
    object.Nombre = formData.nombre;
    object.Descripcion = formData.descripcion;
    object.Costo = formData.costo;
    object.Marca = formData.marca;
    object.FechaRegistro = formData.fechaRegistro;
    object.Sucursal = formData.sucursal;
    object.Estatus = formData.estatus;
    object.NoSerie = formData.noserie;
    object.Area = formData.area;
    object.Proveedor = formData.proveedor;
    object.TipoActivo = formData.tipoActivo;
    object.FechaCompra = formData.fechaCompra;
    object.Depreciacion = formData.depreciacion;
    object.ArchivoFactura = formData.archivoFactura;
    object.AsignacionesList = formData.asignaciones;
    return object;
  }
}
import { IConversacion } from '@/model/IConversacion';
import { IChatFilter } from '@/model/chat/IChatFilter';
import { IServiceResponse } from '@/model/IServiceResponse';
import { ISesionApp } from '@/model/ISesionApp';
import { ChatContact, IChatContact } from '@/model/chat/IChatContact';
import BaseService from '@/services/base/BaseService';
import { BehaviorSubject } from 'rxjs';
import { IFileData } from '@/model/IFileData';
import { ISessionHub } from '@/model/chat/ISessionHub';

export class ChatService extends BaseService 
{
    public MessageReceived: BehaviorSubject<IConversacion|null>;
    public ContactStatusChange: BehaviorSubject<ISessionHub|null>;
    public NewMessagesTotal: BehaviorSubject<number>; 
    constructor(param?: any)
    {
      super(param);
      this.MessageReceived = new BehaviorSubject<IConversacion|null>(null);                  
      this.ContactStatusChange = new BehaviorSubject<ISessionHub|null>(null);
      this.NewMessagesTotal = new BehaviorSubject<number>(0);
    } 
    protected async getActiveConnectionsStaffApp(empresaId: number,url: string): Promise<IChatContact[]>
    {
        const contactos: IChatContact[] = [];
        if (empresaId <= 0) return [];        
        this.SetEmpresaId = empresaId;
        const contacts:IServiceResponse = await this.Get(url,null,null,null,true,undefined,null);
        if(contacts.Response)
        {
            for (const contacto of contacts.Response) 
            {
                const c = new ChatContact(contacto);
                c.inicializaChat();
                contactos.push(c);                            
            }
        }           
        return contactos;
        
    }
    public async ActiveConnectionsStaffAppAsync(sesion:ISesionApp): Promise<IChatContact[]>
    {        
        let contactos: IChatContact[] = [];
        if (!sesion.SyncData.IsEmpleado)
        {
            sesion.IdEmpresa
            const empresas = sesion.SyncData.Empresas;
            if (empresas != null && empresas.length > 0)
            {
                for (const empresa of empresas)
                {
                    if(empresa && empresa.EmpresaId > 0)
                    {
                        contactos = await this.getActiveConnectionsStaffApp(empresa.EmpresaId, "IndexChat/ActiveConnectionsStaffApp");                         
                        /*if (resp && resp.length > 0)
                        {
                            foreach (var rr in aux)
                            {
                                var conversacion = await InicializaContacto(rr, e);
                                result.Add(conversacion);
                            }
                        }*/
                    }
                }
            }
        }
        else
        {
            //El usuarioApp es un empleado.
            const empresaId = sesion.SyncData.EmpresaEmpleadoId;            
            contactos = await this.getActiveConnectionsStaffApp(empresaId, "IndexChat/ActiveConnectionsEmpleadosApp");                       
        }
        return contactos;
    }
    public async GetChat(From:IChatContact, To: IChatContact): Promise<void>
    {
        if(!From || !To) return;
        if (To.Chat?.MensajesRestantes  && To.Chat?.MensajesRestantes  <= 0) return;
        const filter:IChatFilter = {
            UserIdFrom : From.Id,
            UserIdTo : To.Id,
            TipoEmisor: From.Tipo,
            TipoReceptor: To.Tipo,
            PppageRows : To.Chat? To.Chat?.PppageRows:0,
            PppageId : To.Chat? To.Chat?.PppageId:0,
            TotalRows : 0,
            Chat : []
        };
        this.SetEmpresaId = To.EmpresaId;        
        const chatResponse:IServiceResponse = await this.Post("IndexChat/GetChat",filter,null,null,()=> this.showError(),true,false);        
        if(chatResponse.Response)
        {
            this.SetChatToContact(To,chatResponse.Response,From);
        }                  
    }
    public async GetFileChat(msj: IConversacion)
    {
      const response:IServiceResponse = await this.Get('IndexChat/GetFileChat/' + msj.ConversacionId,null,null,()=> this.showError(),true,false);
      if(response && response.Response)
      {    
        this.storage.SaveBase64String(msj.Archivo,response.Response.FileContent)   
      }      
    }
    protected SetChatToContact(contact:IChatContact,chatResponse:IChatFilter|null,contactFrom: IChatContact):void
    {        
        if(!chatResponse || !contact) return;
        if(contact.Chat && chatResponse.Chat)
        {
            contact.Chat.PppageId++;
            contact.Chat.TotalRows = chatResponse.TotalRows;
            contact.Chat.CurrentRows = contact.Chat.CurrentRows + chatResponse.Chat.length;
            contact.Chat.MensajesRestantes = contact.Chat.TotalRows - contact.Chat.CurrentRows ;
            for (const m of chatResponse.Chat)            
            {
                m.Sended = m.EmisorId == contactFrom.Id && m.Tipo === contactFrom.Tipo;                
                this.AddChat(contact, m, true);
            }                   
        }
    }
    protected AddChat(user:IChatContact, message:IConversacion, top:boolean)
    {        
        if (user.Chat && message)
        {
            const index = user.Chat.Mensajes.findIndex((m:IConversacion) => m.ConversacionId === message.ConversacionId );
            if (index < 0)
            {
                if (top)
                {
                    user.Chat.Mensajes.unshift(message);
                }
                else
                {
                    user.Chat.Mensajes.push(message);
                }
            }
        }
    }
    public onMessageReceived(contactos:IChatContact[],message: IConversacion|null):IChatContact|null
    {                
        if(contactos && message)
        {
            const from = contactos.find((x: any) => x.Id === message.EmisorId && x.Tipo === message.Tipo);
            if (from)
            {              
              message.Sended = false;
              this.AddChat(from, message, false); 
              if(from.Chat)
              {             
                from.Chat.NewMessage++;
                from.LastMessage = message;
              }
              return from;
            }
        }
        return null;
    }
    /*protected getEmpresaUsuarioApp(sesion:ISesionApp,EmpresaId: number):IEmpresaUsuarioApp|undefined
    {
        return sesion.SyncData.EmpresaUsuarioApp?.find((x:IEmpresaUsuarioApp) => x.EmpresaId === EmpresaId);
    }*/
    protected BuildMessage(sended: boolean, message: string, FromUsuarioId: IChatContact  , ToUsuarioId: IChatContact): IConversacion
    {   
        const msg:IConversacion|any = { ConversacionId: 0,
                EmisorId: FromUsuarioId.Id,
                FechaEnvio: new Date(),
                Mensaje: message,
                Archivo: '',
                VisibleFrom: true,
                Tipo:FromUsuarioId.Tipo,
                EmpresaUsuario: FromUsuarioId.EmpresaUsuario,
                EmpresaUsuarioApp: FromUsuarioId.EmpresaUsuarioApp,             
                Activo: true,
                Receptores: [
                {
                    ConversacionReceptorId: 0,
                    ConversacionId: 0,
                    ReceptorId: ToUsuarioId.Id,
                    Tipo:ToUsuarioId.Tipo,
                    Visto: false,
                    FechaVisto: null,
                    VisibleTo: true,
                    Entregado: false,
                    EmpresaUsuario: ToUsuarioId.EmpresaUsuario? ToUsuarioId.EmpresaUsuario:null,
                    EmpresaUsuarioApp: ToUsuarioId.EmpresaUsuarioApp? ToUsuarioId.EmpresaUsuarioApp:null,              
                    Activo: true
                }
                ],
                Sended: sended
                };
        return msg;
    }
    public async SendMessageAsync(sended: boolean,sesion:ISesionApp,nwMessage :string,from:IChatContact ,toContact:IChatContact, FileAttachment?:IFileData)
    {
        let messageToSend: IConversacion|any; 
        if(from.IsApp)
        {
            //const empresaUsuarioApp = this.getEmpresaUsuarioApp(sesion,toContact.EmpresaId);            
            messageToSend = this.BuildMessage(sended,nwMessage,from,toContact);
            messageToSend.EmpresaId = from.EmpresaId;
            messageToSend.EmisorName = from.getFullName;             
        }
        else
        {
            //es un emplado.
            messageToSend = this.BuildMessage(sended,nwMessage,from,toContact);
            messageToSend.EmisorName = from.getFullName; 

        }        
        messageToSend.Archivo = FileAttachment ? FileAttachment.fileName : null;
        messageToSend.FileContent = FileAttachment ? FileAttachment.fileContent : null;
        let envio:IServiceResponse;
        if (!sesion.SyncData.IsEmpleado)
        {
            envio = await this.Post("RealTime/SendMessageFromApp", messageToSend,null,null,()=> this.showError(),true,false);
        }
        else
        {
            envio = await this.Post("RealTime/SendMessageFromWeb", messageToSend,null,null,()=> this.showError(),true,false);
        }
        
        if(envio && envio.Response)
        {
            this.AddChat(toContact,envio.Response,false);
        }        
    } 
    public ChatMessageRecived(parametros: any)
    {
        const msjs = this.NewMessagesTotal.value + 1;
        this.NewMessagesTotal.next(msjs);
        this.MessageReceived.next(parametros);
    }      
}
import { createI18n  } from 'vue-i18n'
import jsonLangEsDefault from '@/locales/es.json';
import jsonLangEnDefault from '@/locales/en.json';
import moment from 'moment';
import 'moment/locale/es-mx';
import { eDefaultLanguageApp } from './model/GloblasEnum';

function createMyi18n()
{
  const defaultLang = eDefaultLanguageApp.laguageDefault;
  moment.locale(defaultLang);
  /* If you want to use (say) en items when an item is not available in your desired locale, 
       set the "fallbackLocale" option in the createI18n:
    */
    const i18n = createI18n({  
        legacy: false, // you must set `false`, to use Composition API
        locale: defaultLang,
        fallbackLocale: defaultLang,
        messages:{
            'es-mx': jsonLangEsDefault,
            'en-us': jsonLangEnDefault
    }});
    return i18n;
} 
const Myi18n = createMyi18n();
export function getMyi18n(): any
{    
  return Myi18n;
}
export default Myi18n;
//es-mx siempre debe ser el primer elemento del arreglo.
export const languagesLst = [{id:'es-mx',title:'Español México',logo:'es-mx'},{id:'en-us',title:'English',logo:'en-us'}]
import { BehaviorSubject} from "rxjs";





//import { useDialog } from 'primevue/usedialog';

import { useDialog } from 'primevue/usedialog';
import { useConfirm } from "primevue/useconfirm";
import IAlertifyService from "@/model/services/IAlertifyService";
import { getAlertifyService } from "./GlobalFunctions";
import { ISesionApp } from "@/model/ISesionApp";
import AppOptionsComponent from "@/components/shared/AppOptions/AppOptionsComponent.vue";
export default class AppUIManagerService 
{     
    protected classPanel = 'page-panel-main';
    protected classPanelLoading = 'page-panel-main-loading';
    protected classPanelLoadingChild = 'child-loading';
    protected alertify: IAlertifyService;
    public ActiveMenu: BehaviorSubject<any>;
    public ReloadCurrent: BehaviorSubject<any>;
    public ReloadCurrentAction:any =null;
    public Confirm:any = null;
    public Dialog:any = null;
    public globalLabels: any = null;
    public get getActiveMenu():any{return this.ActiveMenu? this.ActiveMenu.value:null;}
    constructor()
    {
      this.ActiveMenu = new BehaviorSubject<any>(null);
      this.ReloadCurrent = new BehaviorSubject<any>(null);
      this.globalLabels = {};      
      this.alertify = getAlertifyService();
    }
    public startUiServices()
    {
        this.buildConfirm();
        this.buildDialog();
    }
    public setGlobalLabel(label:string, text: string):void
    {
        this.globalLabels[label] = text;
    } 
    public getGlobalLabel(label:string):string
    {        
        return this.globalLabels[label];
    }
    protected getMainPanel()
    {
        return document.getElementsByClassName(this.classPanel)[0];
    }    
    public loadingUi(element?:any)
    {
        const panel = element? element : this.getMainPanel();
        if(panel)
        {
            if(panel.classList.contains(this.classPanelLoading))
            {                
                const del = document.querySelector('.'+this.classPanelLoadingChild);                
                if(del)
                    del.remove();
                panel.classList.remove(this.classPanelLoading);
            }
            else
            {
                const espere = this.getGlobalLabel('espere');
                const child = document.createElement('div');
                child.innerHTML = `<div><div><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i><span>${espere}</span></div></div>`;
                child.classList.add(this.classPanelLoadingChild);
                panel.classList.add(this.classPanelLoading);
                panel.appendChild(child);
            }
        }
    }
    public reloadCurrent()
    {        
        this.ReloadCurrent.next(true);
    }
    public async reloadCurrentByAction()
    {
        if (this.ReloadCurrentAction)
        {
            await this.ReloadCurrentAction();
        }
    }
    public async showShortAppSettings(title: string)
    {
        await this.showModal(AppOptionsComponent,null,title,null);        
    }
    /*Confirm*/
    protected buildConfirm()
    {   
        this.Confirm = useConfirm();     

        

    }
    protected confirmWeb(header:string, message:string ,fnOk:any, fnCancel:any)
    {  
        this.Confirm.require(
        {
            header: header,
            message: message,    
            accept: async () => 
            {
                if(fnOk) await fnOk();        
            },
            reject: async () =>
            {
                if(fnCancel) await fnCancel();            
            }});   
    }
    protected alertmWeb(header:string, message:string ,fnOk:any)
    {  
        this.Confirm.require(
        {
            header: header,
            message: message,
            rejectLabel:' ',
            acceptLabel: this.getGlobalLabel('aceptar'),   
            accept: async () => 
            {
                if(fnOk) await fnOk();        
            }
        });   
    }
    public  async showConfirm(header:string, message:string ,fnOk:any, fnCancel:any)
    {  

        this.confirmWeb(header,message,fnOk,fnCancel);





















    }
    public  async showAlert(header:string, message:string ,fnOk:any)
    {  

        this.alertmWeb(header,message,fnOk);
















    }
    /*Modal */
    protected buildDialog()
    {

        //this.Dialog = useDialog();

        this.Dialog = useDialog();
    }
    protected getPropsModalWeb(title:string,params:any,fnOnClose:any)
    {
        return {
            data: params,
            props: 
        {
            header: title,
            breakpoints:{
                '960px': '75vw',
                '640px': '90vw'
            },
            modal: true,
            closable:true                     
        },    
        onClose: (opt:any) => 
        {
            if(fnOnClose)
                fnOnClose(opt);                
        }  };
    }
    public async showModal(componentParam: any,parms:any,title:string,fnAction?:any)
    {        
        if(this.Dialog)
        { 
            this.Dialog.open(componentParam,this.getPropsModalWeb(title,parms,fnAction));
        }        

        /*if(this.Dialog)
        { 
            this.Dialog.open(component,this.getPropsModalWeb(title,parms,fnAction));
        }*/

        //remove || platform.type === 'web'        










    }
    public showError(msg:string)
    {
        this.alertify.Error(msg);
    }
    public showSuccess(msg:string)
    {
        this.alertify.Sucess(msg);
    }
    public getProfileImageAppUser(sesion:ISesionApp):string
    {
        if(sesion.SyncData && sesion.SyncData.IsEmpleado && sesion.SyncData.Empleado && 
            sesion.SyncData.Empleado.Usuario && sesion.SyncData.Empleado.Usuario.Detalle)
        {
           return  sesion.SyncData.Empleado.Usuario.Detalle.FotoBase64;
        }
        return ''; 
    }
}
//this is a Composables
import { ref, onMounted, onUnmounted } from 'vue'
export function useWindowChange() 
{ 
  const screen = ref({
    IsMenuMobile:false,
    IsSmall:false,
    IsMobile:false,
    IsOpen:false,
  })
  function useHideButtonMenu()
  {
    const element = document.getElementById("liCloseMenu");
    element?.classList.add("d-none");     
  }
  const OpenMenu = () =>
  {  
    const element = document.getElementById("liCloseMenu");
    element?.classList.remove("d-none");
    screen.value.IsOpen = true;
  }
  const CerrarMenu = () =>
  {
    const mnu = document.getElementById('btnCloseMnu');  
    if(mnu)
    {
      mnu.click();    
      useHideButtonMenu();
      screen.value.IsOpen = false;
    } 
  }   
  function CheckMenuMobile()
  {
    /*
    navbar-expand-sm = mobile menu on xs screens <576px
    navbar-expand-md = mobile menu on sm screens <768px
    navbar-expand-lg = mobile menu on md screens <992px
    navbar-expand-xl = mobile menu on lg screens <1200px
    navbar-expand = never use mobile menu
    (no expand class) = always use mobile menu
    */
    const size = window.innerWidth;
    if(size < 992)
    {
      screen.value.IsMenuMobile = true;        
    }
    else
    {
      screen.value.IsMenuMobile = false;        
    }
  }
  function update(/*event: any*/) 
  {    
    const isMobileDevice = window.navigator.userAgent.toLowerCase().includes("mobi");
    const size = window.innerWidth;
    CheckMenuMobile();
    if(size <= 1400)
    {
      screen.value.IsSmall = true;        
    }
    else
    {
      screen.value.IsSmall = false;
    }
    if(size < 992)
    {
      screen.value.IsMobile = true;        
    }
    else
    {
      screen.value.IsMobile = false;
    }
    if(isMobileDevice)
    {
      screen.value.IsMobile = true;
      screen.value.IsSmall = true; 
    }
    if(!screen.value.IsMenuMobile && screen.value.IsOpen)
    {        
      CerrarMenu();
    }      
  }  
  onMounted(() =>{ 
    window.addEventListener("resize", update);
    update();
  });
  onUnmounted(() => window.removeEventListener('resize', update));
  return { screen,OpenMenu,CerrarMenu }
}
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import IAlertifyService from '@/model/services/IAlertifyService';
import IDataBaseService from '@/model/services/IDataBaseService';
import ContextDBService from '../base/ContextDBService';
import IContextDBService from '@/model/services/db/IContextDBService';
import CService from '../CService';






///platform.type ==='desk' && platform.onWeb === '0'
import DataBaseDeskService from '@/all-platforms/desk/services/DataBaseDeskService'
/// #endif

import DataBaseWebService from '../../all-platforms/web/services/DataBaseWebService';


import AlertifyVueService from './AlertifyServiceVue';

export function isEmail(value: string) 
{
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
}
export function traduceTexto(value:string) : string
{
    let newval = value;
    try
    {
        const { t } = useI18n();
        newval = t(value);
    }
    catch(e)
    {
      //  console.log(e);
      newval = value;
    }
    return newval 
}
export function getAlertifyService():IAlertifyService
{
    let service:IAlertifyService;   

    service = new AlertifyVueService();




    return service;
}

export function getDataBaseService(cService:CService):IDataBaseService
{
  const platform = getPlatform();  
  const context:IContextDBService = new ContextDBService();  
  let service:IDataBaseService;

  service = new DataBaseWebService(cService);








  return service;
}
export function getPlatform()
{  
  return process.env.VUE_APP_PLATFORM?.toLowerCase();
}
export function getWindow():any
{
  const win:any = window;
  return win;
}
export function getAppVersion()
{
  return process.env.VUE_APP_VERSION;
}
export function toDate2 (date:any, format = 'L') 
{        
  return moment(date,format).toDate();
}
export function toDate (date:any) 
{        
  return moment(date).toDate();
}
/*export function toDateShort (date:any) 
{        
  return moment(date).format()
}*/
export function getLocale () 
{        
  const i18nLocale = useI18n();
  return i18nLocale.locale;     
}
export function dateShort (date:any,localValue?:any) 
{        
  return dateFormat(date,'L',localValue);
}
export function dateFormat (date:any, format = 'L hh:mm a',localValue?:any) 
{        
  if(!localValue)
  {
    const i18nLocale = useI18n();
    moment.locale(i18nLocale.locale.value); 
  }
  else
  {
    moment.locale(localValue); 
  }
  return moment(date).format(format)
}
export function empresaShortName (empresa: any) 
{        
  return empresa? empresa.RazonSocial:"";
}
 /**Convierte un string en un byte[] to c# */
export function Base64ToArrayBuffer2(base64: string)
{
  const res = Base64ToArrayBuffer(base64);  
  const buffer = new Uint8Array(res);
  return [].slice.call(buffer); 
}

export function Base64ToArrayBuffer(base64: string)
{
  const binarystring = window.atob(base64);
  const len = binarystring.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++)
  {
      bytes[i] = binarystring.charCodeAt(i);
  }
  return bytes.buffer;
}
export function ArrayBufferaToBase64(buffer: any)
{
  return btoa(buffer.reduce((x:any, y:any) => x + String.fromCharCode(y), ''));
}
export function formatCurrency(value:any,localValue?:any) 
{  
  return localValue? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value) : value;
}
export function hasPermiso(permisos:any[]|undefined, permiso:number):boolean
{
  if(!permisos || permisos.length <= 0) return false;
  const existe = permisos.find((x:any) => x.PermisoMenuId === permiso);
  return existe;
}
export async function ConvertBlobToBase64 (blob: Blob): Promise<string>
{
  return new Promise((resolve, reject) => 
  {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => { resolve(reader.result as string); };
    reader.readAsDataURL(blob);
  });
}
  
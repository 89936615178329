<template>
    <div class="splash">        
    </div>
</template>
<script setup lang="ts">
</script>
<style scoped>
.splash
{
    width: 100%;
    height: 100vh;
    background-color: var(--splash-bg);
    background-repeat: no-repeat;  
    background-size: 150px;
    background-position: center;
    background-image: var(--splah-img);    
}

</style>
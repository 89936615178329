import { ISesionApp } from './../model/ISesionApp';
import { defineStore } from "pinia";
import mainConfigDefault from '@/assets/config/main-config-default.json';
import { SesionApp } from "@/model/ISesionApp";
import {ServerConfig} from '@/all-platforms/main-config';
import { ISesionUsuarios } from '@/model/ISesionUsuarios';
import { eRolAppUsuario, eSettingsNames } from '@/model/GloblasEnum';
import { IAppSystemSettings } from '@/model/IAppSystemSettings';

let appConfig = {...mainConfigDefault};
appConfig = {...ServerConfig};
const empresaTemplate: any = null;
//si en los stores ocurre algun error la aplicacion simplemente falla
//no se muestra nada ni en console ni en el explorador al menos usando electron.
export const useMainStore = defineStore('mainstore', 
{
  state: () => {
      return {
        appConfig:appConfig,
        sesionApp: new SesionApp(),
        empresa:empresaTemplate,
        appLanguage:'',
        appSettings:[]
      }
    },
  getters: 
  {
    getAppConfig: state => state.appConfig,
    getSesionApp: state => state.sesionApp,
    getHubConnection: state => state.sesionApp.HubConnection,
    getEmpresa:state => state.empresa,
    getRolAppUsuario : (state):eRolAppUsuario => 
    {
      if(state.sesionApp && state.sesionApp.SyncData && state.sesionApp.SyncData.syncValid())
      {
        return state.sesionApp.getRolAppUsuario();
      }
      else
        return eRolAppUsuario.None;      
    },
    getAppSettings : (state):IAppSystemSettings[] => {return  state.appSettings as IAppSystemSettings[]},
    getAppLanguage:(state):string => {return state.appLanguage;}
  },
  actions:
  {
    setSesionApp (sesion: ISesionApp)
    {
      this.sesionApp = sesion;
    },
    setHubConnection (hub: ISesionUsuarios)
    {    
      this.sesionApp.HubConnection = hub;
    },
    setEmpresa (empresa: any)
    {
      this.empresa = empresa;
    },
    closeSesionApp ()
    {
      this.sesionApp = new SesionApp();
    },
    getRelacionEmpresa(empresaId:number)
    {
      return this.sesionApp.getRelacionEmpresa(empresaId);
    },
    setAppSettings (settings: IAppSystemSettings[]|any)
    {
      this.appSettings = settings;
    },
    setAppLanguage (lang:string)
    {
      this.appLanguage = lang;
    },
    isDarkTheme()
    {
      const setting:IAppSystemSettings|any = this.appSettings.find((x:IAppSystemSettings) =>x.Setting===eSettingsNames.theme);
      if(setting && setting.Value.split('_')[0].toLocaleLowerCase()==='dark') return true;
      return false;
    }
  }
});
import { IServiceResponse, ServiceResponse } from './../../model/IServiceResponse';
import { INotificacionErrorUI, NotificacionErrorUI } from './../../model/notificacion-ui/INotificacionUI';
import axios, { AxiosInstance} from 'axios'
//import { IsDebugMode } from './GlobalError';
import { eTiposClienteRest } from '@/model/GloblasEnum';
import { useServicesFactoryStore } from '@/store/services-factory-store'
import { ISesionApp } from '@/model/ISesionApp';
import CService from '../CService';
import { useMainStore } from '@/store/main-store';
interface IRequestParam
{
  empresaId:number|null,
  usuarioId:number|null,
}
export default class ClientHttpService
{
  protected urlBase: string|null = null;
  protected httpClient: AxiosInstance;
  protected requestError: INotificacionErrorUI|null = null;
  protected requestResponse: IServiceResponse|any = null;
  protected requestParam: IRequestParam = {empresaId:null, usuarioId:null};
  public get HasErrorRequest () { return this.requestError != null;}
  public get RequestError () { return this.requestError;}
  public get RequestResponse ():any { return this.requestResponse;}
  public set SetEmpresaId(empresaId: number){ this.requestParam.empresaId = empresaId;}
  public set SetUsuarioId(usuarioId: number){ this.requestParam.usuarioId = usuarioId;}
  protected SetErrorRequest = (error: any) =>
  {
    /*if(IsDebugMode())  
    {
      console.log(error); 
    }   
    console.log(error); */
    this.requestError = new NotificacionErrorUI(error);
  }
  /*public get GetErrorRequest ():INotificacionErrorUI|null
  {
    return this.requestError;
  }
  public getResponse = () => { return this.myResponse;}  */        
  constructor(baseUrl?: string)
  {             
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;    
    this.urlBase = baseUrl? baseUrl:null;
    this.httpClient = axios.create();        
    this.httpClient.interceptors.response.use(function (response)
    {
      return response;
    },
    function (error)
    {   
      self.SetErrorRequest(error);        
      return Promise.reject(error);
    });          
  }  
  public HasBaseUrl():boolean
  {      
    return this.urlBase? true:false;
  }
  public GetBaseUrl()
  {
    return this.urlBase;      
  }
  public SetBaseUrl(url: string)
  {
    this.urlBase = url;      
  }    
  protected Combine(url: string):string
  {           
      let newurl = '';
      newurl = this.urlBase? (this.urlBase + (this.urlBase.endsWith('/') ? '' : '/' ) + url) : '';    
      return newurl;
  }
  protected async GetSesion(): Promise<ISesionApp|null>
  {
    const service = useServicesFactoryStore().getLoginService; 
    const sesion = service? await service.getSession():null;
    return sesion;
  }
  protected NewRequest()
  {
    this.requestError = null;
    this.requestResponse = null;    
  }     
  protected GetCService(): CService
  {
    return (useServicesFactoryStore().getCService as CService);     
  } 
  protected getAppLanguage():string
  {
    return useMainStore().getAppLanguage;
  }   
  protected async BuildConfig(verb: string, url: string,data?: any, transforn?:any,responseType?:any): Promise<object>
  {
    this.NewRequest();
    const Sesion :ISesionApp|null = await this.GetSesion();    
    const config = {
        method: verb,        
        url: this.Combine(url), 
        responseType: responseType? responseType:null,        
        headers:{}        
    };
    const lang = this.getAppLanguage();
    const headers = {'ia-TipoClienteREST':eTiposClienteRest.AppMovil,
                     'accept-language': lang? lang : 'es-MX',
                     'Content-Type': 'application/json',
                     'accept':'application/json'}
    if (Sesion)
    {
      const cservice = this.GetCService();
      const idEmpresa:string|null = this.requestParam.empresaId? cservice?.Encrypt(this.requestParam.empresaId.toString()): (Sesion.IdEmpresa?Sesion.IdEmpresa:null);
      const idUsuario:string|null = this.requestParam.usuarioId? cservice?.Encrypt(this.requestParam.usuarioId.toString()): (Sesion.IdUsuario?Sesion.IdUsuario:null);      
      config.headers =  {...headers,
                         'ia-AppUsuarioId': Sesion.AppUsuarioIdString? Sesion.AppUsuarioIdString:null,
                         'ia-IsApp':'true',                          
                         'ia-IdEmpresa': idEmpresa,//? cservice?.Encrypt(idEmpresa):null,//Sesion.IdEmpresa?Sesion.IdEmpresa:null,
                         'ia-IdUsuario': idUsuario,//? cservice?.Encrypt(idUsuario):null,// Sesion.IdUsuario?Sesion.IdUsuario:null,
                         'ia-Tokenvz': Sesion.User?.TokenVz,                         
                         'Authorization': `Bearer ${Sesion.User?.TokenApi}`,
                         'ia-TipoClienteREST':eTiposClienteRest.AppMovil};
      this.requestParam = {empresaId:null, usuarioId:null};
    }    
    if(transforn)
    {            
      if(!data)
      {
      const config2={...config,transformResponse : [(data:any)=> { return this.CustomTransform(data,transforn);}]};      
      return config2;
      }
      else
      {
        const config2={...config,data,transformResponse : [(data:any)=> { return this.CustomTransform(data,transforn);}]};      
        return config2;
      }
    } 
    else
    {
      if(data)
      {
        const config2={...config,data};
        return config2;
      }
    }  
    
    
    return config;
  }
  protected CustomTransform(data: any,transforn:any)
  {      
    const obj = this.ResponseToJson(data);   
    const evalResp = this.EvalResponse(obj);
    this.requestResponse = new ServiceResponse(evalResp);    
    this.requestResponse.Response = null;
    return transforn(evalResp? evalResp.Response:data);
  }
  protected ResponseToJson(data: any): any
  {
    let resp = null;
    try
    {
      resp = JSON.parse(data);
    }
    catch(e)
    {      
      resp = null;
    }
    return resp;
  } 
  public async Get(url: string,fnOnResponse?:any, transform?: any,responseType?:any):Promise<any>
  {   
    const config = await this.BuildConfig('GET',url,null,transform,responseType);
    return this.httpClient(config).then(r => 
      {
        if(!transform)
        {
          this.requestResponse = this.EvalResponse(r? r.data:null);
        }  
        //executes when no error
        if(fnOnResponse)
        {
          fnOnResponse(r);
        }
        return r? r.data:null;
      })
    ;
  }
  public async Post(url: string,data: any,fnOnResponse?:any, transform?: any,responseType?:any):Promise<any>
  {       
    const config = await this.BuildConfig('POST',url,data,transform,responseType);    
    return this.httpClient(config).then(r => 
      {        
        if(!transform)
        {
          this.requestResponse = this.EvalResponse(r? r.data:null);
        }  
        //executes when no error
        if(fnOnResponse)
        {          
          fnOnResponse(r);
        }
        return r? r.data:null;
      })
    ;
  }
  protected EvalResponse(r:any):IServiceResponse|undefined
  {    
    if(r)
    {      
      const response:IServiceResponse = new ServiceResponse(r);
      return response? response:r;
    }
    else
    {
      return undefined;
    }
  }  
}
import { IAppUsuarios } from "@/model/IAppUsuarios";
import BaseService from "./base/BaseService";
import IDataBaseService from "@/model/services/IDataBaseService";
import { IServiceResponse } from '@/model/IServiceResponse';
export default class RegistroService extends BaseService
{      
  protected DBServie :IDataBaseService | undefined;    
  constructor(param?: any,dbService?: IDataBaseService)
  {
    super(param); 
    this.DBServie = dbService;                 
  }
  public async getFlag():Promise<any>
  {            
    const response = await this.DBServie?.getFlagRegistro();
    return response;      
  }
  public async removeFlag():Promise<any>
  {            
    const response = await this.DBServie?.removeFlagRegistro();
    return response;      
  }
  public async RegistroAsync(appUsuarios:any):Promise<IAppUsuarios|null>
  {       
    const response:IServiceResponse = await this.Post("IndexRegistroApp/RegistraUsuario",appUsuarios,null,null,()=> this.showError(),true,false);
    if(response && response.Response)
    { 
      const result = response.Response as IAppUsuarios;    
      await this.DBServie?.setFlagRegistro(result.AppUsuarioId);
      return result;
    }
    return null;
  }
}
import { ePage, eRolAppUsuario } from "./GloblasEnum";
import { AppUserDataSincronizacion, IAppUserDataSincronizacion } from "./IAppUserDataSincronizacion";
import { IAppUsuarios } from "./IAppUsuarios";
import { ISesionUsuarios } from "./ISesionUsuarios";

export interface ISesionApp
{
    Active:boolean,
    AppUsuarioIdString: string,
    User:IAppUsuarios|null,
     /**Id de la empresa a la que pertenece el empleado. */
    IdEmpresa:string,
    IdUsuario:string,
    SyncData: IAppUserDataSincronizacion,
    
    HubConnection:ISesionUsuarios|null, 
    ItemsPerPage:number,  
    canAccess():boolean;
    getRolAppUsuario():eRolAppUsuario;
    getRelacionEmpresa(empresaId:number):any;
    /**Id de la empresa a la que pertenece el empleado. */
    getEmpresaId():number;
}
export class SesionApp implements ISesionApp
{
    constructor(data?: any)
    {
        Object.assign(this, data);        
    }
    public Active = false;
    public AppUsuarioIdString = '';
    public User:IAppUsuarios|null = null;
     /**Id de la empresa a la que pertenece el empleado. */
    public IdEmpresa = '';
    public IdUsuario = '';
    public SyncData: IAppUserDataSincronizacion = new AppUserDataSincronizacion();    
    public HubConnection:ISesionUsuarios|null = null;
    public ItemsPerPage:number = ePage.ItemsPerPage;
    public canAccess():boolean
    {
     return this.Active && this.User && this.User.AppUsuarioId > 0 ? true: false;
    }
    public getRolAppUsuario():eRolAppUsuario
    {
      if(this.SyncData && this.SyncData.syncValid())
      {
        if(this.SyncData.IsEmpleado)
        {
          return eRolAppUsuario.Empleado;
        }
        else if (this.SyncData.isProveedor())
        {      
          return eRolAppUsuario.Proveedor;
        }
        else if (this.SyncData.isCliente())
        {      
          return eRolAppUsuario.Cliente;
        }
        else
        {
          return eRolAppUsuario.None;
        }   
      }
      return eRolAppUsuario.None;
    }
    public getRelacionEmpresa(empresaId:number):any
    {
      const rol = this.getRolAppUsuario();
      const getEmpresa = (id:number) =>
      {
        if(id > 0 && this.SyncData && this.SyncData.syncValid() && this.SyncData.EmpresaUsuarioApp)
        {
          const relacion = this.SyncData.EmpresaUsuarioApp.find((x:any) => x.EmpresaId === id);
          return relacion;
        }
        return null;
      };
      let rel = null;
      switch(rol)
      {
        case eRolAppUsuario.None:
          rel = null;
          break;
        case eRolAppUsuario.Cliente:
          rel = getEmpresa(empresaId);          
          break;
        case eRolAppUsuario.Proveedor:
          rel = getEmpresa(empresaId);          
          break;
      }
      return rel;
    }
    /**Id de la empresa a la que pertenece el empleado. */
    public getEmpresaId()
    {
      return this.SyncData.EmpresaEmpleadoId;
    }
}




import { createRouter as CreateRouterVue, 
         createWebHistory as  CreateWebHistoryVue, 
         createMemoryHistory as createMemoryHistoryVue} from 'vue-router'

import { RouteRecordRaw } from 'vue-router';
import { eAppPaths, ePlatforms, eRolAppUsuario } from '@/model/GloblasEnum';
import {useServicesFactoryStore}  from '@/store/services-factory-store';
import LoginComponent from '../components/login/LoginComponent.vue';
import AboutView from '@/views/shared/AboutView.vue';
import View1 from '@/components/empresa/View1Component.vue';
import View2 from '@/components/empresa/View2Component.vue';
import View3 from '@/components/empresa/View3Component.vue';
import NotFound from '@/views/shared/NotFound.vue';
import HomeEmpresaComponent from '@/components/home/HomeEmpresaComponent.vue';
import { ISesionApp } from '@/model/ISesionApp';

const RoutesRol = [
    {name:'empresa',Rol:[eRolAppUsuario.Empleado]}, //home empresa
    {name:'ticket',Rol:[eRolAppUsuario.Empleado]},    
    {name:'ticketid',Rol:[eRolAppUsuario.Empleado]},
    {name:'prodcutoscs',Rol:[eRolAppUsuario.Empleado]},
    {name:'productoid',Rol:[eRolAppUsuario.Empleado]},
    {name:'c1',Rol:[eRolAppUsuario.Empleado]},
    {name:'c2',Rol:[eRolAppUsuario.Empleado]},
    {name:'c3',Rol:[eRolAppUsuario.Empleado]},

    {name:'cliente',Rol:[eRolAppUsuario.Cliente]}, //home cliente
    {name:'startcliente',Rol:[eRolAppUsuario.Cliente]},
    {name:'clientetickets',Rol:[eRolAppUsuario.Cliente]},
    {name:'tclienteid',Rol:[eRolAppUsuario.Cliente]},

    {name:'proveedor',Rol:[eRolAppUsuario.Proveedor]}, //home proveedor
    {name:'startproveedor',Rol:[eRolAppUsuario.Proveedor]}        
  ];
const FreePaths =['about','registro'];

const getShared=(rol:string)=>
{
  const pathShared = [
    {
      path:'notificaciones',
      name: rol + '/' + 'notificaciones',    
      component: () => import(/* webpackChunkName: "notificaciones" */ '../components/shared/Notificaciones/NotificacionesList.vue')
    },
    {
      path:'profile',
      name: rol + '/' + 'profile',    
      component: () => import(/* webpackChunkName: "myprofile" */ '../components/shared/MiPerfil/MyProfileComponent.vue')
    },
    {
      path:'chat',
      name: rol + '/' + 'chat',    
      component: () => import(/* webpackChunkName: "chat" */ '../components/shared/Chat/ChatComponent.vue')
    }
    
  ];
  return pathShared;
};
const routes: Array<RouteRecordRaw> = [  
    {
      path: '/',    
      name:'ini',
      component: LoginComponent
    },
    {
      path:'/login',
      name:'login',
      component:LoginComponent
    },
    {
      path:'/registro',
      name: 'registro',    
      component: () => import(/* webpackChunkName: "registro" */ '../components/shared/Registro/RegistroComponent.vue')
    },
    {
      path:'/about',
      name:'about',
      component:AboutView
    },
    {
      path:'/empresa',
      name:'empresa',    
      component: HomeEmpresaComponent,
      children: [      
        ...getShared('empresa'),
        { path: 'ticket',
          name:'ticket', 
          component: () => import(/* webpackChunkName: "listticket" */ '../components/empresa/tickets/TicketsComponent.vue') 
        },
        { path: 'ticket/:id',
          name:'ticketid', 
          component: () => import(/* webpackChunkName: "ticketview" */ '../components/shared/Tickets/TicketViewComponent.vue') 
        },
        { path: 'prodcutoscs',
          name:'prodcutoscs',           
          component: () => import(/* webpackChunkName: "prodcutoscs" */ '../components/empresa/productos/ProductsComponent.vue')              
        },
        { path: 'prodcutoscs/:id',
          name:'productoid', 
          component: () => import(/* webpackChunkName: "productoview" */ '../components/shared/Productos/ProductoDetalleComponent.vue') 
        },
        { path: 'c1',name:'c1', component: View1 },
        { path: 'c2',name:'c2', component: View2 },
        { path: 'c3',name:'c3', component: View3 },        
      ]
    },
    {
      path:'/proveedor',
      name:'proveedor',    
      component: () => import(/* webpackChunkName: "home-proveedor" */ '../components/home/HomeProveedorComponent.vue'),
      children:[
        ...getShared('proveedor'),
        {
          path:'startproveedor',
          name:'startproveedor',    
          component: () => import(/* webpackChunkName: "startproveedor" */ '../components/proveedor/StartProveedorComponent.vue')
        }
      ]
    },
    {
      path:'/user',
      name:'user',    
      component: () => import(/* webpackChunkName: "home-app" */ '../components/home/HomeAppComponent.vue'),
      children:[
        ...getShared('user')
      ]
    },
    {
      path:'/cliente',
      name:'cliente',    
      component: () => import(/* webpackChunkName: "home-cliente" */ '../components/home/HomeClientComponent.vue'),
      children:[
        ...getShared('cliente'),
        {
          path:'startcliente',
          name:'startcliente',    
          component: () => import(/* webpackChunkName: "startcliente" */ '../components/cliente/StartClienteComponent.vue')
        },
        {
          path:'clientetickets',
          name:'clientetickets',    
          component: () => import(/* webpackChunkName: "clientetickets" */ '../components/cliente/tickets/TicketsComponent.vue')          
        },
        { path: 'tclienteid/:dominio&:id',
        name:'tclienteid', 
        component: () => import(/* webpackChunkName: "ticketview" */ '../components/shared/Tickets/TicketViewComponent.vue') 
      },
      ]
    },
    {
      path: '/:catchAll(.*)*',
      name: "PageNotFound",
      component: NotFound,
    }
  ]
export function createRouterByPlatform(platform: string)
{
    let router: any;
    if(platform === ePlatforms.web)
    {
        router = CreateRouterVue({  
        history: CreateWebHistoryVue(/*process.env.BASE_URL*/),
        routes
        });  
    }
    if(platform === ePlatforms.desk)
    {
        router = CreateRouterVue({  
        history: createMemoryHistoryVue(/*process.env.BASE_URL*/),
        routes
        });  
    }
    if(platform === ePlatforms.app )
    {  
        router = CreateRouterIo({  
        history: CreateWebHistoryIo(/*process.env.BASE_URL*/),
        routes
        });  
    }    
    return router;
}
export async function CustomGuardWeb(to:any)
{
  const login = eAppPaths.login;   
  //const home = eAppPaths.home;
  const factory = useServicesFactoryStore();
  if(!factory)
  return { name: login };
  const service = factory.getLoginService;  
  if(!service)
  return { name: login };

  //const service = useServicesFactoryStore().getLoginService; 
  //if(!ser)   
  let canAccess  = false;      
  const Sesion:ISesionApp|null = await service.getSession();
  //console.log(Sesion);
  //const haySesion = Sesion && Sesion.Active;        
  if(to.name !== login)
  {
    if(service.isFreePath(to,FreePaths))
    {
      return true;
    }
    canAccess  = service.canUserAccess(Sesion,to,RoutesRol);
    if (!canAccess) return { name: login }; 
    //if (!canAccess && !haySesion) return { name: login };        
    //if (!canAccess && haySesion) return { name: home };            
  }
  return true;
  /*if(to.name === login && haySesion)
  {
    return { name: home };    
  } */
}
export async function CustomGuardApp()
{
  return true; 
}
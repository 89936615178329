<template>
    <div class="page-container page-container-web">
        <slot></slot>
    </div>
</template>
<style scoped>
    .page-container-web
    {
        width: 100%;
        height: 100vh;                        
        overflow: auto;  
    }
</style>
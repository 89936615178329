let laConfig = null

















const serverQA = {    
    titulo:"Virtual Blix App QA",
    baseURL:"https://www.demoerp.virtualblix.com/api/",
    urlSignalR: "https://www.demoia.virtualblix.com/iapushHub"        
};
laConfig = serverQA;









export const ServerConfig = laConfig;
import { INotificacionErrorUI, NotificacionErrorUI } from "@/model/notificacion-ui/INotificacionUI";
import { useMessagesUiStore } from "@/store/messages-ui-store";
import { useServicesFactoryStore } from "@/store/services-factory-store";

export default function(error: any)
{
 try
 {
  const serviceFactory = useServicesFactoryStore();
  const messagesUiStore = useMessagesUiStore();
  const NotiError: INotificacionErrorUI = new NotificacionErrorUI(error);
  if(NotiError)
  {
    const alert = serviceFactory.getAlertifyService;
    messagesUiStore.AddError(NotiError);
    if(alert)
    {
     alert.Error(NotiError.Message);
    }    
  }
  //visible only dev  
  if(IsDebugMode())  
  {
    console.log(error);
  }
 }
 catch(errorCatch:any)
 {
    console.log('Error Fatal[GlobalError.ts]');
    if(IsDebugMode())  
  {
    console.log(errorCatch);
  }
 }
}
export function IsDebugMode()
{
    return process.env.NODE_ENV === 'development' || process.env.VUE_APP_BUILD === 'dev';
}
import { INotificacionErrorUI, INotificacionUI } from './../model/notificacion-ui/INotificacionUI';
import { defineStore } from "pinia";
export const useMessagesUiStore = defineStore('MessagesUiStore', 
{
  state: () =>
  {    
    return {
      Normal: new Array<INotificacionUI>(),
      Error: new Array<INotificacionErrorUI>(),
      Warning: new Array<INotificacionUI>()
    }
  },
  getters: 
  {    
  },
  actions:
  {
    AddMessage (message: INotificacionUI)
    {
      this.Normal.push(message);
    },    
    AddWarning (message: INotificacionUI)
    {
      this.Warning.push(message);
    },   
    AddError (message: INotificacionErrorUI)
    {
      this.Error.push(message);
    }   
  },
});
import { IDocumentoEvidencia } from './../model/tickets/ITicket';
import { ServiceResponse } from './../model/IServiceResponse';
import { IServiceResponse } from '@/model/IServiceResponse';
import BaseService from "./base/BaseService";

export default class DocumentoEvidenciaService extends BaseService
{      
  public EmpresaId:number;
  constructor(param?: any)
  {
    super(param);
    this.EmpresaId = 0;     
  }  
  public async RemoveTicketEvidencia(DocumentoEvidenciaId: number):Promise<IDocumentoEvidencia|null>
  {
    if (!DocumentoEvidenciaId || !this.EmpresaId) return null;  
    this.SetEmpresaId = this.EmpresaId;
    let serviceResponse:IServiceResponse = new ServiceResponse(); 
    const url = `IndexDocumentoEvidencia/RemoveTicketEvidencia/${DocumentoEvidenciaId}`;
    serviceResponse = await this.Get(url,null,null,()=>{this.showError()},true,false);     
    return serviceResponse.Response? serviceResponse.Response:null;    
  }
  
}
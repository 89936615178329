import localforage from "localforage";
import ClientHttpService from "./ClientHttpService";
import {useServicesFactoryStore} from "@/store/services-factory-store";
import { eStatusCode } from "@/model/GloblasEnum";
import IAlertifyService from "@/model/services/IAlertifyService";
import { getAlertifyService } from "../ui/GlobalFunctions";
import { ISesionApp, SesionApp } from "@/model/ISesionApp";
import { useMainStore } from "@/store/main-store";
import AppUIManagerService from "../ui/AppUIManagerService";
import { IFileData } from "@/model/IFileData";
import { StorageService } from "@/services/StorageService";
import { Where } from "@/model/Where";
export default class BaseService
{
    private http: ClientHttpService;    
    protected alertify: IAlertifyService;
    protected appConfig: any;
    protected storage: StorageService;
    public set SetEmpresaId(empresaId: number){ this.http.SetEmpresaId = empresaId;}
    public set SetUsuarioId(usuarioId: number){ this.http.SetUsuarioId = usuarioId;}
    public get SesionPinia():ISesionApp{return this.getSesionFromPinia();}
    constructor(param?: any)
    {              
      this.appConfig = param.appconfig;                          
      this.http = this.appConfig && this.appConfig.baseURL? new ClientHttpService(this.appConfig.baseURL):new ClientHttpService();
      this.alertify = getAlertifyService();  
      this.storage = new StorageService();    
    }
    protected OnError (e: any)
    {
      console.log(e);
    }    
    protected getUrlMultimedia(): string
    {
      let laurl = "";
      if(this.appConfig && this.appConfig.urlMultimedia)
      { 
        laurl = this.appConfig.urlMultimedia;
      }
      return laurl;
    }
    public IsMaxLengthFile(length: number): boolean
    {
      /*in bytes*/
      const ks = length / 1024;
      const mb = ks / 1024;
      return (mb > 4) ? true : false;
    }
    public async DownLoadMultimedia(name: string)
    {    
      const url = this.getUrlMultimedia();
      const fnsave = async () =>
      {
        const response = await fetch(url + name);
        const blob = await response.blob();
        return new Promise((onSuccess, onError) => 
        {
          try 
          {
            const reader = new FileReader() ;
            reader.onload = function()
            { 
              const multimediaa = this.result;
              localforage.setItem(name, multimediaa).then(async ()=> 
              {                               
                const multimedia2 = await localforage.getItem(name);               
                onSuccess(multimedia2);
              })                            
            } ;
            reader.readAsDataURL(blob) ;
          } 
          catch(e) 
          {
            onError(e);
          }
        });
      };       
      const multimedia = await localforage.getItem(name);
      if(multimedia)
      {        
        return multimedia;
      }
      else
      {        
        return await fnsave();
      }                    
    }        
    protected getFactory()
    {
     return useServicesFactoryStore();
    }    
    protected getSesionFromPinia():ISesionApp
    {
      return useMainStore().getSesionApp;
    }
    protected async baseGetSesion():Promise<ISesionApp>
    {
      const loginService = this.getFactory().getLoginService;
      const sesion = await loginService.getSession()
      return sesion? sesion:new SesionApp();
    }
    protected showError()
    {
      const response = this.getInfoResponse();      
      if(response.tipo === eStatusCode.Error)      
      {
        this.alertify.Error(response.msg);
      }
    }
    protected showResponse(omitShowResponse:boolean,omitError:boolean):void
    {
      const response = this.getInfoResponse();          
      if(response.tipo === eStatusCode.Ok)
      {
        if(!omitShowResponse) this.alertify.Sucess(response.msg);        
      }
      else
      {
        if(!omitError)this.alertify.Error(response.msg);        
      }
    }
    /**
     * Se evalua si la peticion tiene error o si fue satisfactoria
     * si no hay error se evalua la respuesta [IServiceResponse]
     * @returns {tipo:0,msg:''}
     */
    protected getInfoResponse()
    {            
      if(this.http.HasErrorRequest)
      {
        const err = this.http.RequestError;
        return {tipo: eStatusCode.Error,msg:err? (err.Message? err.Message:err.ResponseText):''}
      }
      else
      {
        const lastResponse = this.http.RequestResponse;                 
        if(lastResponse)
        {
          if(lastResponse.StatusCode != null)
          {
            if(lastResponse.StatusCode !== eStatusCode.Ok)
            {              
              return {tipo: eStatusCode.Error,msg:lastResponse.StatusDescription}
            }
            if(lastResponse.StatusCode === eStatusCode.Ok)
            {              
              
              return {tipo: eStatusCode.Ok,msg:lastResponse.StatusDescription}
            }
          }
        }
      }
      return {tipo: eStatusCode.Ok,msg:''};
    }
    /** Obtiene la descripcion de un error personalizado
     * si el web service regresa un error interno pero personalizado
     * esta funcion busca el error dentro de la respuesta.
    */
    protected handleErrorCustom(showResponse:boolean):any
    {
      let ok = {tipo: eStatusCode.Ok,msg:''};    
      const resp = this.RequestResponse;            
      if(resp && resp.StatusCode === eStatusCode.Ok)
      {        
        this.showResponse(showResponse,false);
      }
      else if(resp)
      {
        ok.tipo = eStatusCode.Error;
        ok.msg = resp.Message;
        //if(showResponse) 
        this.alertify.Error(resp.Message);   
      }
      if(!resp)
      {
        this.showResponse(showResponse,false);
        ok = this.getInfoResponse();
      }
      return ok;
    }
    /*protected evalResponse()
    {      
      const infoResponse = this.getInfoResponse();
      if(infoResponse.tipo === eStatusCode.Ok)
      {
        this.alertify.Sucess(infoResponse.msg);
      }
      else
      {
        this.alertify.Error(infoResponse.msg);
      }
    }*/
    /**Obtenemos la respuesta del web service
     * IServiceResponse
     */
    protected get RequestResponse():any
    {
      return this.http.RequestResponse;
    }
    protected get RequestError()
    {
      return this.http.RequestError;
    }
    protected getUIManager():AppUIManagerService|any
    {
      return this.getFactory().getUIManager;
    }
    protected async Get(url: string,fnOnResponse?:any, transform?: any,fnOnError?: any,omitShowResponse?:boolean,showLoading?:boolean,responseType?:any):Promise<any>
    {       
      if(showLoading)
      { 
        this.getUIManager().loadingUi();
      }
      return await this.http.Get(url,fnOnResponse,transform,responseType).catch(
        (e)=>
        {     
          if(fnOnError)
          {
            fnOnError(e);
          }        
          this.OnError(e);                 
        }
      ).finally(() =>
      {
        this.handleErrorCustom(omitShowResponse? omitShowResponse:false); 
        if(showLoading)
        { 
          this.getUIManager().loadingUi();
        }
      });      
    }
    protected async Post(url: string,data: any,fnOnResponse?:any, transform?: any,fnOnError?: any,omitShowResponse?:boolean,showLoading?:boolean,responseType?:any):Promise<any>
    {
      if(showLoading)
      { 
        this.getUIManager().loadingUi();
      }      
      return await this.http.Post(url,data,fnOnResponse,transform,responseType).catch(
        (e)=>
        {     
          if(fnOnError)
          {
            fnOnError(e);
          }        
          this.OnError(e);          
        }
      ).finally(() =>
      {
        this.handleErrorCustom(omitShowResponse? omitShowResponse:false); 
        if(showLoading)
        { 
          this.getUIManager().loadingUi();
        }
      }); 
    }
    public ReadFile(file: any):Promise<IFileData>
    {        
      return new Promise((onSuccess) => 
      {                        
          const FileAttachment : IFileData = {fileName:'',fileContent:null,type:'',size:0};                           
          const invalidsize = this.IsMaxLengthFile(file.size);
          if (!invalidsize)
          {                
              FileAttachment.fileName = file.name;
              FileAttachment.type = file.type;
              FileAttachment.size = file.size;
              const reader = new FileReader();
              reader.onload = (e: any) =>
              {                                              
                const buffer = new Uint8Array(e.target.result);
                FileAttachment.fileContent = [].slice.call(buffer);                    
                onSuccess({...FileAttachment});
              };                        
              reader.readAsArrayBuffer(file);                        
          }                              
      });        
    }
    public FilterSearch(filtros?: any): any
    {
      for (const f of filtros)
      {
        f.Type = '1';
      }
      return filtros;
    }
    public FilterPaged(page: number, filtros?: any): any
    {
      if (!filtros)
      {
        filtros = [];
      }
      const wpage = new Where();
      const wperpage = new Where();
      wperpage.NombreParametro = 'pppageRows';
      wperpage.NombreCampo = 'pppageRows';
      wperpage.Type = '2';
      wperpage.Valor = this.SesionPinia.ItemsPerPage;
      wpage.NombreCampo = 'pppageId';
      wpage.NombreParametro = 'pppageId';
      wpage.Type = '2';
      wpage.Valor = page;
      filtros.push(wperpage);
      filtros.push(wpage);
      return filtros;
    }
  public IsMimeImageValid(mime: string): boolean
  {
    const mimes = ['image/jpeg', 'image/png', 'image/jpg'];
    const existe = mimes.find((e: string) =>  e.toString().toLocaleLowerCase() === mime.toString().toLocaleLowerCase());
    return (existe) ? true : false;
  }
  public MaxLengthSup(length: number): boolean
  {
    /*in bytes*/
    const ks = length / 1024;
    const mb = ks / 1024;
    return (mb > 4) ? true : false;
  }  
}